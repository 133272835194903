import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Button, FormControl, FormLabel } from "react-bootstrap";
import Plain from "../layout/containers/Plain";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import {
    searchExpedientsToLawyer,
    getJudicialParties,
    getCourtsByJudicialPartyId,
    renderErrorsByInputName,
    fieldsValidation
} from "./search_lawyers_logic";
import { destroyCookies } from "../login/login_logic";

const SearchLawyers = (props) => {

    const [messageExistsResult,setMessageExistsResult] = useState("");
    const [result,setResult] = useState({});
    const [search,setSearch] = useState({});
    const [judicialParty,setJudicialParty] = useState({});
    const [searching,setSearching] = useState(false);
    const [cleaning,setCleaning] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [judicialParties, setJudicialParties] = useState([]);
    const [courts, setCourts] = useState([]);
    const [errors,setErrors] = useState({});

    useEffect( () => {
        destroyCookies();
    }, []);

    const getResultsOfSearch = async () => {

        const requiredFields = ['judicial_party','court','expedient_number'];
        const responseOfValidation = await fieldsValidation(
            requiredFields,
            search
        );

        if ( 
            typeof responseOfValidation !== "object" && 
            search["expedient_number"].length > 0 && 
            /^$|.*\S+.*/.test(search["expedient_number"]) ) {

            setSearching(true);
            setMessageExistsResult("");
            setResult({});
            setErrors({});
            await searchExpedientsToLawyer(
                    search["court"]["value"],
                    search["expedient_number"].replace('/','-'),
                    setResult,
                    setMessageExistsResult
                );
            setSearching(false);
        }
        else if ( typeof responseOfValidation === "object" ) {
            NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
            setErrors(responseOfValidation);
        }
    };

    const onChangeJudicialParty = async (judicialPartySelected) => {
        if ( judicialPartySelected["value"] !== null ) {
            cleanSearch()
            setJudicialParty(judicialPartySelected);
            setSearch({judicial_party:judicialPartySelected});
            await getCourtsByJudicialPartyId(
                judicialPartySelected["value"],
                setCourts
            );
        }
    };

    const onChangeCourt = async (courtSelected) => {

        if ( courtSelected["value"] !== null ) {
            setSearch({...search, court:courtSelected});
        }
    };

    const cleanSearch = () => {
        setCleaning(true);
        setSearch({});
        setJudicialParty({});
        setCourts({});
        setMessageExistsResult("");
        setResult({});
        setErrors({});
        setCleaning(false);
    };

    const onKeyDown = async (e) => {

        if ( e.key === "Enter" ) {
            await getResultsOfSearch();
        }
    };

    return (
        <Plain {...props} noHeader={true} noFooter={true}>
            <Container fluid={true} className="min-height-100vh bg-size-cover"
                       style={{backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login_wallpaper.png)"}}>
                <Container>
                    <Row style={{minHeight: "100vh"}}>
                        <Col sm={12} md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}} xl={{span: 10, offset: 1}}
                             className="mY-30">
                            <Card className="mY-40@sm+">
                                <Card.Header>
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={{span: 10, offset: 1}} >
                                                <h3 className="mb-0 text-center">Búsqueda de expediente</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-12">

                                            <FormLabel>
                                                <b>Partido Judicial</b>
                                            </FormLabel>
                                            <Select
                                                options={judicialParties}
                                                onChange={selected => {
                                                    onChangeJudicialParty(selected);
                                                    setErrors({...errors, judicial_party:[]});
                                                }}
                                                placeholder="Selecciona un partido judicial"
                                                value={ judicialParty && judicialParty["label"] ? judicialParty : "" } 
                                                onFocus={async e => getJudicialParties(setJudicialParties, setIsLoading)}
                                                isLoading={isLoading}
                                                loadingMessage = { () => "Cargando datos..." }
                                                noOptionsMessage = { () => "No hay datos que mostrar" }
                                            />
                                            { renderErrorsByInputName(errors, "judicial_party") }

                                            <br/>
                                            <FormLabel>
                                                <b>Juzgado</b>
                                            </FormLabel>
                                            <Select
                                                options={courts}
                                                onChange={selected => {
                                                    onChangeCourt(selected);
                                                    setErrors({...errors, court:[]});
                                                }}
                                                placeholder="Selecciona un juzgado"
                                                value={ search && search["court"] ? search["court"] : "" }
                                                noOptionsMessage = { () => "No hay datos que mostrar" } 
                                            />
                                            { renderErrorsByInputName(errors, "court") }

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <br/>
                                            <FormLabel>
                                                <b>Número de expediente:</b>
                                            </FormLabel>
                                            <FormControl
                                                placeholder={"Ingresa el número de expediente a buscar, ejemplo CJJ01/2020"}
                                                className="inputSearcherToLawyer"
                                                onChange={ (e) => {
                                                    if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
                                                        setSearch({...search, expedient_number:e.target.value});
                                                        setErrors({...errors, expedient_number:[]});
                                                    }
                                                    else setErrors({...errors, expedient_number: ['Sólo son permitidos caracteres alfanuméricos y/o diagonal']})
                                                }}
                                                value={ search["expedient_number"] ? search["expedient_number"] : "" }
                                                onKeyDown={(e) => onKeyDown(e)}
                                            />
                                            { renderErrorsByInputName(errors, "expedient_number") }
                                        </div>
                                    </div>
                                    <div className="buttonsExpedientSearchToLawyer">
                                        <Button 
                                            variant="primary"
                                            onClick={ () => getResultsOfSearch() }
                                            disabled={ searching || cleaning }
                                            style={ cleaning ? {display:"none"} : null}
                                            className="buttonSearchExpedientsToLawyer"
                                        >
                                            { searching ? "Buscando..." : "Buscar Expediente"}
                                        </Button>
                                        <Button 
                                            variant="secondary"
                                            onClick={ () => cleanSearch() }
                                            disabled={ cleaning || searching }
                                            style={ searching ? {display:"none"} : null}
                                            className="buttonSearchExpedientsToLawyer"
                                        >
                                            { cleaning ? "Limpiando..." : "Nueva búsqueda"}
                                        </Button>
                                    </div>

                                    <Container>
                                        <Row>
                                            <Col xs={12} md={{span: 8, offset: 2}} className="pT-30">
                                                <h4 className="text-center" style={ messageExistsResult ? null : {display:"none"} } > {messageExistsResult} </h4>
                                                <Card
                                                    className={ result["expedient_number"] ? "cardExpedients" : "d-n"}
                                                >
                                                    <Card.Header
                                                        className="cardHeaderExpedients"
                                                    >
                                                        <h6 className="mb-0 text-center">Resultados de la búsqueda</h6>

                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Container>

                                                            <div className="table-responsive">
                                                                <table className="table table-collapse">
                                                                    <thead>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <th className="text-right">Expediente:</th>
                                                                            <td className="text-justify"><h6>{ result["expedient_number"] }</h6></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th className="text-right">Partido Judicial:</th>
                                                                            <td className="text-justify"><h6>{ result["judicial_party"] }</h6></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th className="text-right">Juzgado:</th>
                                                                            <td className="text-justify"><h6>{ result["court"] }</h6></td>
                                                                        </tr>

                                                                        {
                                                                            result['external'] ? (
                                                                                <>
                                                                                    <tr>
                                                                                        <th className="text-right">Responsable:</th>
                                                                                        <td className="text-justify"><h6>{ result["dependence"] }</h6></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th className="text-right">Dirección:</th>

                                                                                        <td>
                                                                                            <table className="table table-bordered table-collapse">
                                                                                                <thead>
                                                                                                </thead>

                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <th className="text-right">Calle y número:</th>
                                                                                                        <td>{result["street"]} #{result["external_number"]}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th className="text-right">Colonia:</th>
                                                                                                        <td>{result["colony"]}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th className="text-right">Código postal:</th>
                                                                                                        <td>{result["zip_code"]}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <th className="text-right">Municipio:</th>
                                                                                                        <td>{result["municipality"]}, {result["state"]}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            ) :
                                                                            <tr>
                                                                                <th className="text-right">Responsable:</th>
                                                                                <td className="text-justify"><h6>{ result["user"] }</h6></td>
                                                                            </tr>
                                                                        }

                                                                        <tr>
                                                                            <th className="text-right">Recibido desde:</th>
                                                                            <td className="text-justify"><h6>{ result["date"] }</h6></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th className="text-right">Puesto del funcionario:</th>
                                                                            <td className="text-justify"><h6>{ result["group"] }</h6></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Container>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Plain>
    );
};

export default SearchLawyers;
