import React from 'react'
import styles from './NavBar.module.css'

const NavBar = ({
  children,
  className = '',
  ...restOfProps
}) => {
  return (
    <div className={`${styles.navBar} ${className}`} {...restOfProps}>
      <div className={styles.navBar_item}>
        Beneficios
      </div>
      <div className={styles.navBar_item}>
        Tramites disponibles
      </div>
      <div className={styles.navBar_item}>
        Juzgados familiares
      </div>
      <div className={styles.navBar_item}>
        ¿Cómo se usa?
      </div>
    </div>
  )
}

export default NavBar