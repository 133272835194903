import React, { useState } from 'react'
import styles from './ResponsiveHeader.module.css'
import Header from '../header/Header'

const ResponsiveHeader = ({
  className = '',
  ...restOfProps
}) => {

  const [isCollapsibleActive, setIsCollapsibleActive] = useState(false);
  const handleCollapsibleControlClick = () => {
    setIsCollapsibleActive(!isCollapsibleActive);
  }

  return (
    <div
      data-is-collapsible-active={isCollapsibleActive}
      className={`${styles.responsiveHeader} ${className}`}
      {...restOfProps}
    >
      <Header
        orientation={'landscape'}
        isCollapsibleActive={isCollapsibleActive}
        onCollapsibleControlClick={handleCollapsibleControlClick}
      />
      <Header orientation={'portrait'} className={styles.responsiveHeader_portrait} />
    </div>
  )
}

export default ResponsiveHeader