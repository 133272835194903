import React from 'react'
import { Button, Form } from 'react-bootstrap';

const AddOrChangeMultiSignatureButton = ({
  canSignatureBeChanged = false,
  children,
  ...restOfProps
}) => {

  const textButton = canSignatureBeChanged ? 'Cambiar Firma' : 'Agregar Firma';
  const iconClass = canSignatureBeChanged ? 'fas fa-exchange-alt' : 'fas fa-plus';

  return (
    <>
      <Button
        variant="success"
        {...restOfProps}
      >
        {
          Boolean(children)
            ?
            { children }
            :
            <>
              <i className={iconClass}></i>
              <span className='ml-2'>
                {textButton}
              </span>
            </>
        }
      </Button>

      <Form.Text className={"text-muted"}>
        Ingresa firma FIREL para multifirma, firma SAT o FIREL para firma simple
      </Form.Text>
    </>
  )
}

export default AddOrChangeMultiSignatureButton