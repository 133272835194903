import React from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { NewClassRequest } from '../../classes/NewClassRequest';
import { Carousel, Button } from 'react-bootstrap';
import RenderPdfDocument from '../../helpers_components/multifile/RenderDocumentPDF';
import { documentTypeDisplayName } from '../../../services/dataFormatting/getDocumentType';
import { getDocumentTypeFromNotification } from './services/getDocumentTypeFromNotification';

export const getNotificationById = async (notificationId, setData, setShow) => {

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/expedient_notifications/find/${notificationId}`,
    'get',
    null,
    {}
  );

  const responseNotification = await request.executeRequest();
  if (
    responseNotification && responseNotification.code === 200 &&
    responseNotification.response && 
    (responseNotification.response.agreement || responseNotification.response.judgment) &&
    responseNotification.response.notification
  ) {

    const documentType = getDocumentTypeFromNotification(responseNotification.response);

    const documentsView = [
      {
        document_type: documentType,
        ...responseNotification.response[documentType]
      },
      {
        document_type: 'notification',
        ...responseNotification.response.notification
      }
    ]
    setData(documentsView);
    setShow(true);
  } else {
    if (
      responseNotification && responseNotification.code === 404
    ) {
      NotificationManager.error(
        '',
        responseNotification.response.message,
        5000
      );
    } else {
      NotificationManager.error(
        '',
        'Ha ocurrido un error inesperado, inténtalo nuevamente y si el error persiste contacta al administrador...',
        5000
      );
    }
  }
};

export const bodyDocumentsView = (
  currentDocument, setCurrentDocument, 
  documentsView, pause, setPause
) => {
  const documentType = Boolean(documentsView.length)
    ?
    documentsView[currentDocument].document_type
    :
    '';

    const documentTypeToDisplay = documentTypeDisplayName[documentType];

  return (
    documentsView.length > 0 &&
    <div
      onMouseOut={() => setPause(true)}
      onMouseOver={() => setPause(false)}
    >
      <h4 className='text-center'>
        <b>
            {`Documento de ${documentTypeToDisplay}`}
        </b>
      </h4>
      <Carousel 
        pause='hover' 
        activeIndex={currentDocument} 
        onSelect={(selected) => {
          if (!pause) setCurrentDocument(selected) 
        }}
        indicators={false}
      >
        {documentsView.map((doc, i) => (
          <Carousel.Item interval={10000} key={i}>
            <div className='document-pdf-expedients' style={{ minHeight:'70vh', height: 'auto' }}>
              {doc['extension'] === 'pdf' ?
                <RenderPdfDocument
                  doc={doc['path']}
                  currentDocument={documentsView[currentDocument]['path']}
                /> 
                : 
                <div style={{ textAlign:'center' }}>
                  <img 
                    style={{ width:'auto', maxWidth:'70vw', marginTop:'8vh', marginBottom:'10vh' }} 
                    src={doc['path']}
                  />
                </div>
              }
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export const footerDocumentsView = (setCloseModal) => {
  return (
    <>
      <Button variant='secondary' onClick={() => setCloseModal(false)}>Cerrar</Button>
    </>
  );
};