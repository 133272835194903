import React from 'react'
import styles from './PrimaryButton.module.css'
import DefaultButton from '../defaultButton/DefaultButton'

const PrimaryButton = ({
  className = '',
  children,
  ...restOfProps
}) => {
  return (
    <DefaultButton className={`${styles.primaryButton} ${className}`} {...restOfProps}>
      {children}
    </DefaultButton>
  )
}

export default PrimaryButton