import React from 'react'
import ModalComponent from '../../helpers_components/ModalComponent'
import { bodyDocumentsView, footerDocumentsView } from '../../governement_book/notification/notification_received_logic'
import { useExpedientNotificationContext } from './context/ExpedientNotificationContext';

const ExpedientNotificationModal = () => {

  const {
    showModal,
    setShowModal,
    currentNotifications,
    currentDocument,
    setCurrentDocument,
    pauseCarousel,
    setPauseCarousel,
  } = useExpedientNotificationContext();

  return (
    <ModalComponent
      header="Acuse de recibido:"
      body={bodyDocumentsView(
        currentDocument, setCurrentDocument,
        currentNotifications, pauseCarousel, setPauseCarousel
      )}
      footer={footerDocumentsView(setShowModal)}
      show={showModal}
      canClose={true}
      onHide={setShowModal}
      dialogClassName='modal-templates-preview'
    />
  )
}

export default ExpedientNotificationModal