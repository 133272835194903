import _ from "lodash";
import Cookies from "js-cookie";

export const getIsAuth = () => {
  const isAuth = (
    _.has(Cookies.get(), "token_02") &&
    _.has(Cookies.get(), "uid_02") &&
    _.has(Cookies.get(), "client_02")
  )

  return isAuth
}