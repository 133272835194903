import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TextTooltip = ({ children, text, className, ...restOfProps }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>} {...restOfProps}>
      <span className={`d-inline-block ${className}`}>
        {children}
      </span>
    </OverlayTrigger>
  )
}

export default TextTooltip