import { getFileExtensionFromName } from "../../../../services/dataFormatting/getFileExtensionFromName";
import { getFileNameWithoutExtension } from "../../../../services/dataFormatting/getFileNameWithoutExtension";

export const getDocumentNameWithExtensionInLowercase = (documentName) => {
  let filExtension = '';
  let fileNameWithoutExtension = '';

  filExtension = getFileExtensionFromName(documentName);
  fileNameWithoutExtension = getFileNameWithoutExtension(documentName);

  return `${fileNameWithoutExtension}.${filExtension.toLowerCase()}`

}