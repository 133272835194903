import React, { useContext, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import DynamicForm from "../../dynamic_form/DynamicForm";
import OneColumn from "../../layout/containers/OneColumn";
import LoaderContext from "../../layout/shared/loader_context";
import { executeStage, previousHandler } from "./render_form_logic";

const FirstStep = props => {
  const [sequence, setSequence] = useState(
    props.history.location.state.params.sequence
  );
  const [form, setForm] = useState(props.history.location.state.params.form);
  const [lastStep, setLastStep] = useState(false);
  const [stage, setStage] = useState(
    props.history.location.state.params.stage
      ? props.history.location.state.params.stage
      : null
  );
  const [flagError, setFlagError] = useState(false);
  const [mode, setMode] = useState(props.history.location.state.params.mode);
  const [applicant, setApplicant] = useState(
    props.history.location.state.params.applicant
  );
  const [formTitle, setFormTitle] = useState(
    props.history.location.state.params.formTitle
  );
  const [previosUrl, setPreviousUrl] = useState(
    props.history.location.state.params.previous_url
      ? props.history.location.state.params.previous_url
      : ""
  );    

  const loader = useContext(LoaderContext);

  const previousUrlHandler = async url => {
    await previousHandler(
      url,
      setFormTitle,
      setPreviousUrl,
      setForm,
      setSequence,
      setMode
    );
  };

  const onSubmitHandler = async (form, partial = false) => {

    try {
      loader.show(true)
      await executeStage(
        form,
        partial,
        sequence,
        props,
        setFormTitle,
        setSequence,
        setStage,
        setPreviousUrl,
        setFlagError,
        setForm,
        setLastStep,
        setMode,
        setApplicant
      )
    }
    catch (error) { console.log(error); }
    finally {
      loader.show(false)
    }
  };

  let pageConfig = {
    active: "home",
    title: "Form"
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      noTitle={true}
    >
      <section className="home">
        <Row>
          <Col xs={12}>
            <DynamicForm
              onSubmit={(models, partial) => {
                onSubmitHandler(models, partial);
              }}
              history={props.history}
              form={form}
              lastStep={lastStep}
              formTitle={formTitle}
              stage={stage}
              hasErrors={flagError}
              setHasErrors={setFlagError}
              previousUrl={previosUrl}
              previousUrlHandler={previousUrlHandler}
              setLastStep={setLastStep}
              mode={mode}
              applicant={applicant}
            />
          </Col>
        </Row>
      </section>
    </OneColumn>
  );
};

export default FirstStep;
