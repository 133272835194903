import React from 'react'
import styles from './PrimaryCard.module.css'
import PrimaryButton from '../../button/primaryButton/PrimaryButton'

const PrimaryCard = ({
  children,
  className = '',
  onDownloadButtonClick = () => { },
  ...restOfProps
}) => {
  return (
    <div className={`${styles.primaryCard} ${className}`} {...restOfProps}>
      <div className={styles.primaryCard_icon}>
        <i className="fas fa-file-download"></i>
      </div>
      <div className={styles.primaryCard_content}>
        {children}
      </div>
      <PrimaryButton onClick={onDownloadButtonClick} className={styles.primaryCard_downloadButton}>
        Descargar
      </PrimaryButton>
    </div>
  )
}

export default PrimaryCard