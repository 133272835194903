import KJUR from 'jsrsasign';
import forge from 'node-forge';

export let message = "";
export let PKCS7creado = "";
export let extFileUpload = "";


export function SelectFileSign(ctrlFile) {
  try {
    var file = ctrlFile;
    if (file != null) {
      let archivo = file.name;
      extFileUpload = archivo.split('.')[1];
    }

    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {

      fileReader.onload = (e) => {
        var bufferFile = e.target.result;
        message = ConvertToHex(bufferFile);

        if (message != '') {
          resolve(true);
        }
        resolve(false);
      };

    });

  }
  catch (error) {
    console.error(error);
  }
}

export function SelectPfx(pfxFile) {
  if (pfxFile != null) {
    var archivo = pfxFile.name;

    var ext = archivo.split('.')[1];
    if (ext != 'pfx') {
      return false;
    }

    if (message == "") {
      return true;
    }


  } else {
    return false;
  }
}

export function OpenPfx(ctrlFile, password) {
  try {

    var file = ctrlFile;
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {


      reader.onload = (e) => {
        try {
          var bufferFile = e.target.result;
          var pkcs12Der = forge.util.binary.raw.encode(new Uint8Array(bufferFile));
          var p12Asn1 = forge.asn1.fromDer(pkcs12Der);
          let _contentFileP12 = validaPassword(p12Asn1, password)
          if (!_contentFileP12) {
            resolve(false);
          } else {
            //Se Obtienen La llave Privada y el Certificado del Archivo pfx
            var certBags = _contentFileP12.getBags({ bagType: forge.pki.oids.certBag });
            var pkeyBags = _contentFileP12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
            var certBag = certBags[forge.pki.oids.certBag][0];
            var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];

            var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
            var certPEM = forge.pki.certificateToPem(certBag.cert);

            privateKeyPem = privateKeyPem.replace(/\r\n/g, '\n');
            certPEM = certPEM.replace(/\r\n/g, '\n');
            PKCS7creado = CreatePKCS7(certPEM, privateKeyPem, message);

            if (PKCS7creado != '') {
              resolve(true);
            }
            resolve(false);
          }
        } catch (error) {
          reject('Wrong file or password');
        }
      };
    });

  }
  catch (error) {
    console.error(error)
  }
}

function validaPassword(p12Asn1, password) {
  try {
    let _contentFileP12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);
    return _contentFileP12
  } catch (e) {
    console.log(e);
    // return false;
  }
}


function CreatePKCS7(certPEM, privateKeyPEM, message) {
  var param = {
    content: { hex: message },
    certs: [certPEM],
    signerInfos: [{
      hashAlg: 'sha256',
      sAttr: {
        SigningTime: {}
      },
      signerCert: certPEM,
      sigAlg: 'SHA256withRSA',
      signerPrvKey: privateKeyPEM
    }]
  };

  try {
    var signedData = KJUR.asn1.cms.CMSUtil.newSignedData(param);
    var pkcs7 = RemoveCMSHeader(signedData.getPEM());
    return pkcs7;

  } catch (error) {
    console.error(error);
  }
}

function RemoveCMSHeader(cms) {
  var pkcs7 = ""
  try {
    pkcs7 = cms.replace("-----END CMS-----", "").replace("-----BEGIN CMS-----", "").replace(/(\r\n|\n|\r)/gm, "");
  }
  catch (error) {
    console.error(error);
  }
  return pkcs7;
}

function ConvertToHex(arrayBuffer) {
  const buff = new Uint8Array(arrayBuffer);
  const hexOctets = [];

  for (let i = 0; i < buff.length; ++i)
    hexOctets.push(byteToHex[buff[i]]);

  return hexOctets.join("");
}

const byteToHex = [];

for (let n = 0; n <= 0xff; ++n) {
  const hexOctet = n.toString(16).padStart(2, "0");
  byteToHex.push(hexOctet);
}
