import React, {useContext, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {initForm, SignUp} from "./login_logic";
import NotificationListContext from "../alert_notification_table/notification_context";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import { useSessionManagerContext } from "../../context/sessionManagerContext/sessionManagerContext";
import { backgroundImageStyles } from "../../inLineStyles/backgroundImageStyles/backgroundImageStyles";

const Login = props => {
    const [form, setForm] = useState(initForm);
    const Loader = useContext(LoaderContext);
    const Notifications = useContext(NotificationListContext);
    const { handleSessionManager, tokenExpirationTimeRef } = useSessionManagerContext();

    const onChangeHandler = event => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const onSubmitFormHandler = async e => {
        e.preventDefault();
        Loader.show(true);
        await SignUp(form, props, Notifications, handleSessionManager, tokenExpirationTimeRef);
        Loader.show(false);
    };

    return (
        <Plain {...props} noHeader={true} noFooter={true}>
            <Container
                fluid={true}
                className="min-height-100vh bg-size-cover"
                style={backgroundImageStyles}
            >
                <Container>
                    <Row style={{minHeight: "100vh"}}>
                        <Col
                            sm={12}
                            md={{span: 10, offset: 1}}
                            lg={{span: 8, offset: 2}}
                            xl={{span: 6, offset: 3}}
                            className="mY-30"
                        >
                            <Card className="mY-40@sm+">
                                <Card.Header>
                                    <Container>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={{span: 10, offset: 1}}
                                                className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                                            >
                                                <h4 className="mb-0 subtitle text-center">Bienvenido a la
                                                    plataforma</h4>
                                                <h3 className="mb-0 text-center">Portal del Poder Judicial del Estado de Jalisco, Portal Ciudadano</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Header>
                                <Card.Body>
                                    <Container>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={{span: 10, offset: 1}}
                                                className="pT-30"
                                            >
                                                <p className="mB-30 text-justify">
                                                    Para iniciar sesión, proporciona la información
                                                    solicitada a continuación
                                                </p>

                                                <Form
                                                    className="form-signin"
                                                    onSubmit={e => onSubmitFormHandler(e)}
                                                >
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>
                                                            <b>* Email:</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Correo electrónico"
                                                            required
                                                            autoFocus
                                                            name="user"
                                                            onChange={onChangeHandler}
                                                            autoComplete="off"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicPassword">
                                                        <Form.Label>
                                                            <b>* Contraseña:</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Contraseña"
                                                            required
                                                            name="password"
                                                            onChange={onChangeHandler}
                                                            value={form.password}
                                                        />
                                                    </Form.Group>

                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        className="px-4 mT-20 btn-responsive"
                                                    >
                                                        Entrar
                                                    </Button>
                                                    <p className="mT-20">
                                                        ¿Aún no tienes una cuenta?, regístrate{" "}
                                                        <b>
                                                            <Link to="/register"> aquí</Link>
                                                        </b>
                                                        .
                                                    </p>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                                <Card.Footer>
                                    <Container>
                                        <Row>
                                            <Col
                                                xs={12}
                                                md={{span: 10, offset: 1}}
                                                className="pY-10"
                                            >
                                                ¿Olvidaste tu contraseña? Para recuperarla haz clic
                                                <b>
                                                    <Link to="/forgot-password"> aquí</Link>
                                                </b>
                                                .
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Plain>
    );
};
export default Login;
