const defaultValue = '';

export const documentTypeDisplayName = {
  agreement: 'Acuerdo',
  notification: 'Notificación',
  judgment: 'Sentencia',
  privateJudgment: 'Sentencia',
  'undefined': defaultValue,
  'null': defaultValue,
  '': defaultValue,
}