import _ from "lodash"
import { typeOfDocumentAvailableToSignList } from "../constants/typeOfDocumentAvailableToSignList"

export const getCanTypeOfDocumentBeSigned = (documentType) => {
  if (!_.isString(documentType)) {
    return false
  }

  const canTypeOfDocumentBeSigned = typeOfDocumentAvailableToSignList.includes(documentType);
  return canTypeOfDocumentBeSigned
}