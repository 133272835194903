import { useContext, useEffect, useState } from 'react'
import { getExpedientDetails } from '../services/getExpedientDetails';
import LoaderContext from '../../../../layout/shared/loader_context';
import { NotificationManager } from 'react-notifications';

const useExpedientConsultationState = (props) => {

  const [expedientDetails, setExpedientDetails] = useState({});
  const { match: { params: { accessToken } } } = props;
  const loader = useContext(LoaderContext);

  const handleGetExpedientDetails = async () => {
    try {
      loader.show(true);
      const expedientDetailsResponse = await getExpedientDetails(accessToken);
      setExpedientDetails(expedientDetailsResponse);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      loader.show(false);
    }
  }

  useEffect(() => {
    handleGetExpedientDetails();
  }, [])

    const handleDownloadDocuments = () => {
        const urlDowload = `${process.env.REACT_APP_URL_API_LARAVEL}/qrs/${accessToken}`

    const dowloadAnchorTag = document.createElement('a');
    dowloadAnchorTag.download = true;
    dowloadAnchorTag.href = urlDowload;
    dowloadAnchorTag.click();
  }

  return {
    expedientDetails,
    accessToken,
    handleDownloadDocuments,
  }
}

export default useExpedientConsultationState
