import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Badge, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import { logOut, setImgAvatar } from "./navbar_logic";
import { Link } from "react-router-dom";
import NotificationListContext from "../../alert_notification_table/notification_context";
import LoaderContext from "./loader_context";
import { useSessionManagerContext } from "../../../context/sessionManagerContext/sessionManagerContext";

const NavBar = props => {
    //GET COOKIES
    const { isAuth_02, names_02, paternal_surname_02, uid_02, app_config } = Cookies.get();
    const app_config_parsed = app_config ? JSON.parse(app_config) : null;

    //INITIAL STATE
    const countNotifications = useContext(NotificationListContext);
    const Loader = useContext(LoaderContext);
    const [avatar, setAvatar] = useState("");
    const [isResponsive, setIsResponsive] = useState(window.innerWidth < 992);
    const { handleFreeMemorySessionManager } = useSessionManagerContext();

    useEffect(() => {
        (async function () {
            const { avatar_02 } = Cookies.get();
            if (avatar_02 && avatar_02 !== "null") {
                setAvatar(avatar_02);
            } else {
                setAvatar(`${process.env.PUBLIC_URL}/img/user_avatar.png`);
            }
        })();
    }, []);

    //LOGOUT METHOD
    const onLogOutHandler = async () => {
        Loader.show(true);
        await logOut(props, handleFreeMemorySessionManager);
        Loader.show(false);
    };

    useLayoutEffect(() => {
        function updateSize() {
            setIsResponsive(window.innerWidth < 992);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const profileWrapper = () => {
        if (isAuth_02) {
            return (
                <div className="nilo-profile-wrapper">
                    <div className="image-wrapper">
                        <Image src={avatar} />
                    </div>
                    <div className="profile-info-wrapper">
                        <label className="name w-100">
                            {names_02} {
                                (paternal_surname_02 !== 'null' && paternal_surname_02 !== null)
                                && paternal_surname_02
                            }
                        </label>
                        <label className="username w-100">{uid_02}</label>
                        <Link to="/edit-profile" className="btn btn-primary">Editar Perfil</Link>
                    </div>
                </div>
            );
        }

        return '';
    };

    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand as={Link} to="/home">
                    <img src={app_config_parsed && app_config_parsed['app_citizen_header_logo_url_image'] ? app_config_parsed['app_citizen_header_logo_url_image'] : `${process.env.REACT_APP_URL_BASE_LARAVEL}/img/nilo_completo_01.svg`} alt="application logo" />
                </Navbar.Brand>
                <Navbar.Text>{props.title ? props.title : ""}</Navbar.Text>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <div className={isResponsive ? 'd-block' : 'd-none'}>
                            {profileWrapper()}
                            <Nav.Link as={Link} to="/change-password">
                                <i className="fas fa-key" /> Cambiar Contraseña
                            </Nav.Link>
                            <Nav.Link onClick={() => onLogOutHandler()}>
                                <i className="fas fa-sign-out-alt" /> Cerrar Sesión
                            </Nav.Link>
                        </div>
                    </Nav>
                    <Nav>
                        <div className={!isResponsive ? 'd-block' : 'd-none'} style={{ minWidth: "14rem" }}>
                            <NavDropdown
                                title={setImgAvatar(countNotifications.count)}
                                id="collasible-nav-dropdown"
                                style={isAuth_02 ? { display: "block" } : { display: "none" }}
                            >
                                {profileWrapper()}
                                <NavDropdown.Item as={Link} to="/avatar_config">
                                    <i className="fas fa-camera-retro" /> Configurar avatar
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/change-password">
                                    <i className="fas fa-key" /> Cambiar Contraseña
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => onLogOutHandler()}>
                                    <i className="fas fa-sign-out-alt" /> Cerrar Sesión
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

export default NavBar;
