import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TextTooltip = ({ children, text, ...restOfProps }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>} {...restOfProps}>
      <span className="d-inline-block">
        {children}
      </span>
    </OverlayTrigger>
  )
}

export default TextTooltip