import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { NewClassRequest } from "../classes/NewClassRequest";
import { getAllAlertNotifications } from "../alert_notification_table/alert_notification_logic";
import { getDecodedJwt } from "../../services/dataFormatting/getDecodedJwt";
import { getDocumentTypeFromNotification } from "../governement_book/notification/services/getDocumentTypeFromNotification";
import _ from "lodash";

export const initForm = {
  user: "",
  password: ""
};


// ###REFACTORIZADA
export const SignUp = async (form, props, notificationsContext, handleSessionManager, tokenExpirationTimeRef) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_in`,
    "post",
    null,
    {
      email: form.user,
      password: form.password,
      app_id: 2
    }
  );
  const isLogged = await request.executeRequest();

  if ( isLogged.code === 200 ) {

    if ( isLogged.response.error ) {

      NotificationManager.error( isLogged.response.message );
    }
    else {
      await setCookies(isLogged.completeResponse, props);
      const allNotifications = await getAllAlertNotifications( isLogged.response.user.id );
      const decodedToken = getDecodedJwt(isLogged.completeResponse.data.data.jwt);
      tokenExpirationTimeRef.current = decodedToken.exp - decodedToken.iat; 
      handleSessionManager();
      if ( allNotifications.length > 0 ) {
        notificationsContext.updateCount( true, "sum", allNotifications.length );
        NotificationManager.warning( 
          allNotifications.length === 1 ? "Cuentas con una notificación pendiente" : `Cuentas con ${allNotifications.length} pendientes`
        );      
      }
    }
  }
  else {

    NotificationManager.error( isLogged.response.message );
  }
};

export const getExpedientNotificationsIds = async () => {
  const notificationsIdsRequest = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedient/notifications`,
    "get",
    null,
    {}
  );

  const notificationsIdsResponse = await notificationsIdsRequest.executeRequest();
  const { code, response = {} } = notificationsIdsResponse || {};

  if (Number(code) !== 200) {
    return []
  }

  const { notificationIds = [] } = response;

  if (!_.isEmpty(notificationIds))
    return Object.values(notificationIds)

  return []
};

async function setCookies(response, props) {
  try {
    //HEADERS
    Cookies.set("token_02", response.headers["access-token"]);
    Cookies.set("uid_02", response.headers["uid"]);
    Cookies.set("client_02", response.headers["client"]);
    //USER
    Cookies.set("userId_02", response.data.data.user.id);
    Cookies.set("names_02", response.data.data.user.names);
    Cookies.set("avatar_02", response.data.data.user.avatar);
    Cookies.set(
      "paternal_surname_02",
      response.data.data.user.paternal_surname === null ? '' : response.data.data.user.paternal_surname
    );
    Cookies.set("regime_02", response.data.data.user.regime);
    Cookies.set("isAuth_02", true);
    Cookies.set("authentication_token_02", response.data.data.jwt );

    //SI TODO ESTA BIEN MANDAMOS AL HOME
    props.history.push("/home");
  } catch (error) {
    // ERROR AL GUARDAR LAS COOKIES
    if (typeof error === "string" && error !== "")
      NotificationManager.error(error, "Error:");
  }
}

export function destroyCookies() {
  Cookies.remove("client_02", { path: "/" });
  Cookies.remove("uid_02", { path: "/" });
  Cookies.remove("token_02", { path: "/" }); // removed!
  Cookies.remove("userId_02", { path: "/" }); // removed!
  Cookies.remove("names_02", { path: "/" }); // removed!
  Cookies.remove("regime_02", { path: "/" }); // removed!
  Cookies.remove("paternal_surname_02", { path: "/" });
  Cookies.remove("isAuth_02", { path: "/" });
  Cookies.remove("avatar_02", { path: "/" });
  Cookies.remove("authentication_token_02", { path: "/" });
  Cookies.remove("app_config", { path:"/" } );
}

export const getExpedientNotification = async (
  documentNotificationIds,
  setShowModal,
  setCurrentNotifications,
) => {
  for (let documentNotificationId of documentNotificationIds) {
    const request = new NewClassRequest(
      `${process.env.REACT_APP_URL_API_LARAVEL}/expedient_notifications/find/${documentNotificationId}`,
      "get",
      null,
      {}
    );
    const notificationResponse = await request.executeRequest();
    if (
      notificationResponse && notificationResponse.code === 200 &&
      notificationResponse.response &&
      (notificationResponse.response.agreement || notificationResponse.response.judgment) &&
      notificationResponse.response.notification
    ) {
      const documentType = getDocumentTypeFromNotification(notificationResponse.response);

      const documents = [
        {
          document_type: documentType,
          ... notificationResponse.response[documentType]
        },
        {
          document_type: 'notification',
          ... notificationResponse.response.notification
        }
      ];

      setCurrentNotifications(documents);
      setShowModal(true);
      return;
    }
  }
};
