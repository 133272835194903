export const CertificateRequest = {
    FileName: '',
    Pkcs7Original: '',
    CertificateType: '',
    UserName: ''
};

export const Result = {
    state:'',
    description:'',
    privateKey:''
};

export const File = {
    HexFile:'',
    FileName:''
};

// export const Key = {
//     Pfx:'',
//     FileName:'',
//     PrivatePEM:'',
//     CertPEM:'',
//     Status:'',//boolean
//     Error:''
// };

export const InfoCer = {
    PrivatePEM:'',
    CertPEM:'',
    Status:'',//boolean
    Error:'',
    SerialNumber:'',
    CURPFirel:'',
    CommonName:'',
    FechaInicioVigencia:'',
    FechaFinVigencia:'',
    VigenciaValida:''//boolean
};

export const Signers = {
    certs: [],
    signerInfos: [{
      hashAlg:'',
      sAttr: {
        SigningTime: {}
      },
      signerCert:'',
      sigAlg:'',
      signerPrvKey:''
    }]
};

// export const SignerInfo = {
//     hashAlg:'',
//     sAttr: {
//       SigningTime: {}
//     },
//     signerCert:'',
//     sigAlg:'',
//     signerPrvKey:''
// };
//
// export const SAttr = {
//     SigningTime: {}
// };

export const ReqSignedData = {
    content: {
      hex:{}
    },
    certs: [],
    signerInfos: [{
      hashAlg:'',
      sAttr: {
        SigningTime: {}
      },
      signerCert:'',
      sigAlg:'',
      signerPrvKey:''
    }]
};

export const Content = {
    hex:{}
}
