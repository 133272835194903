import React from 'react'
import styles from './SecondaryButton.module.css'
import DefaultButton from '../defaultButton/DefaultButton'

const SecondaryButton = ({
  className = '',
  children,
  ...restOfProps
}) => {
  return (
    <DefaultButton className={`${styles.secondaryButton} ${className}`} {...restOfProps}>
      {children}
    </DefaultButton>
  )
}

export default SecondaryButton