import React, { useEffect ,useState } from 'react';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import { NewClassRequest } from '../../classes/NewClassRequest';
const Cover = ({expedientId}) => {
    const [cover, setCover] = useState('');
    async function getCover() {
        const request = new NewClassRequest(`${process.env.REACT_APP_URL_API_LARAVEL}/coverpage/translate/${expedientId}`, "get", null, {});

        const response = await request.executeRequest();

        try {
            const { status, data: { data} } = response.completeResponse           
            if (status !== 200) {
                NotificationManager.error('Error al obtener la portada');
                return;
            }
            if(_.has(data, 'coverTemplate')){
                setCover(data.coverTemplate);
            }
        } catch (error) {
            NotificationManager.error('Error al obtener la portada');
        }
    }
    
    useEffect(() => {
        getCover();
    }, []);

    return (
        <div className="cover">
            <div className="cover__content">
                <div dangerouslySetInnerHTML={{ __html: cover}} />
            </div>
        </div>
    );
}
export default Cover;