import React from 'react'
import styles from './ExpedientDetail.module.css'

const ExpedientDetail = ({
  title,
  children,
  className = '',
  ...restOfProps
}) => {
  return (
    <div className={`${styles.expedientDetail} ${className}`} {...restOfProps}>
      <div className={styles.expedientDetail_key}>
        {title}
      </div>
      <div className={styles.expedientDetail_value}>
        {children}
      </div>
    </div>
  )
}

export default ExpedientDetail