import React from 'react'
import styles from './MainLayout.module.css'
import MainContent from '../mainContent/MainContent'
import ResponsiveHeader from '../responsiveHeader/ResponsiveHeader'
import Loader from '../../../layout/shared/Loader'

const MainLayout = ({
  children,
  className = '',
  ...restOfProps
}) => {
  return (
    <div className={`${styles.mainLayout} ${className}`} {...restOfProps}>
      <ResponsiveHeader />
      <MainContent>
        {children}
      </MainContent>
      <Loader />
    </div>
  )
}

export default MainLayout