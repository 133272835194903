import React from 'react'
import styles from './ExpedientDetails.module.css'
import ExpedientDetail from '../expedientDetail/ExpedientDetail'
import { useExpedientConsultationStateContext } from '../../pages/expedientConsultation/context/expedientConsultationState/ExpedientConsultationState';

const ExpedientDetails = ({
  className = '',
  ...restOfProps
}) => {

  const { expedientDetails } = useExpedientConsultationStateContext();
  const {
    expedientNumber,
    court,
    judicialParty,
    kindJudgment,
    matter,
    via,
  } = expedientDetails;

  return (
    <div className={`${styles.expedientDetails} ${className}`} {...restOfProps}>
      <div className={styles.expedientDetails_title}>
        Expediente: {expedientNumber}
      </div>

      <div className={styles.expedientDetails_details}>
        <ExpedientDetail title={'Juzgado'}>
          {court}
        </ExpedientDetail>
        <ExpedientDetail title={'Partido Judicial'}>
          {judicialParty}
        </ExpedientDetail>
        <ExpedientDetail title={'Tipo de Juicio'}>
          {kindJudgment}
        </ExpedientDetail>
        <ExpedientDetail title={'Materia'}>
          {matter}
        </ExpedientDetail>
        <ExpedientDetail title={'Vía'}>
          {via}
        </ExpedientDetail>
      </div>

    </div>
  )
}

export default ExpedientDetails