import { useContext, useEffect, useRef, useState } from 'react';
import Cookies from "js-cookie";
import { logOut } from '../../../components/layout/shared/navbar_logic';
import { NewClassRequest } from '../../../components/classes/NewClassRequest';
import { NotificationManager } from 'react-notifications';
import { getDecodedJwt } from '../../../services/dataFormatting/getDecodedJwt';
import LoaderContext from '../../../components/layout/shared/loader_context';

const remainingTimeToShowRequestNewTokenModal = 30000; //Tiempo en milisegundos
const remainingTimeToRequestNewToken = 120000; //Tiempo en milisegundos

const useSessionManager = () => {
  const [isReadyToFetchToken, setIsReadyToFetchToken] = useState(false);
  const [isFetchTokenModalShowing, setIsFetchTokenModalShowing] = useState(false);
  const [countdownTimeToLogout, setCountdownTimeToLogout] = useState(0);
  const timeoutToFetchNewTokenRef = useRef();
  const timeoutToShowTokenRetrievalModalRef = useRef();
  const timeoutToLogoutRef = useRef();
  const tokenExpirationTimeRef = useRef();
  const countdownIntervalToLogoutRef = useRef();

  const Loader = useContext(LoaderContext);

  useEffect(() => {
    const { authentication_token_02 } = Cookies.get();
    if (authentication_token_02) {
      handleSessionManager();
    } else {
      handleFreeMemorySessionManager();
    }
    return handleFreeMemorySessionManager;
  }, []);

  const getTimeoutToFetchNewToken = (timingTime) => {
    return setTimeout(() => {
      const timeToShowFetchTokenModal = remainingTimeToRequestNewToken - remainingTimeToShowRequestNewTokenModal;
      handleReadyToFetchToken(timeToShowFetchTokenModal);
    }, timingTime);
  }

  const getTimeoutToShowTokenRetrievalModal = (timingTime) => {
    return setTimeout(() => {
      const timeToLogout = remainingTimeToShowRequestNewTokenModal;
      handleReadyToShowFetchTokenModal(timeToLogout)
    }, timingTime);
  }

  const getTimeoutToLogout = (timingTime) => {
    return setTimeout(() => {
      handleLogOut();
    }, timingTime);
  }

  const handleReadyToFetchToken = (timingTime) => {
    setIsReadyToFetchToken(true);
    enableTimeoutToShowTokenRetrievalModal(timingTime);
  }

  const handleReadyToShowFetchTokenModal = (timingTime) => {
    setIsFetchTokenModalShowing(true);
    handleCountdownToLogout(timingTime);
    enableTimeoutToLogout(timingTime);
  }

  const handleCountdownToLogout = (countdownTimeLimit) => {
    const timeInSecondsTologout = parseInt(countdownTimeLimit / 1000);
    setCountdownTimeToLogout(timeInSecondsTologout);
    countdownIntervalToLogoutRef.current = setInterval(handleCountdownToLogoutIntervalAction, 1000);
  }

  const handleCountdownToLogoutIntervalAction = () => {
    setCountdownTimeToLogout((currentState) => {
      if (currentState > 0) {
        return --currentState
      }
      else {
        clearInterval(countdownIntervalToLogoutRef.current);
        return 0
      }
    })
  }

  const handleFreeMemorySessionManager = () => {
    setIsFetchTokenModalShowing(false);
    setIsReadyToFetchToken(false);
    setCountdownTimeToLogout(0);
    localStorage.removeItem("tokenExpirationDateStored");
    clearTimeout(timeoutToFetchNewTokenRef.current);
    clearTimeout(timeoutToShowTokenRetrievalModalRef.current);
    clearTimeout(timeoutToLogoutRef.current);
    clearInterval(countdownIntervalToLogoutRef.current);
  }

  const handleSessionManager = () => {
    const currentDate = new Date();

    const tokenExpirationDateStored = localStorage.getItem("tokenExpirationDateStored");
    if (tokenExpirationDateStored) {
      const tokenExpirationDate = new Date(tokenExpirationDateStored);
      const differenceTime = tokenExpirationDate.getTime() - currentDate.getTime();

      if (differenceTime <= 0) {
        handleLogOut();
        return
      }
      if (differenceTime <= remainingTimeToShowRequestNewTokenModal) {
        const timeToLogout = differenceTime;
        handleReadyToShowFetchTokenModal(timeToLogout);
        return
      }
      if (differenceTime <= remainingTimeToRequestNewToken) {
        const timeToShowRequestNewTokenModal = differenceTime - remainingTimeToShowRequestNewTokenModal;
        handleReadyToFetchToken(timeToShowRequestNewTokenModal);
        return
      }
      const timeToRequestNewToken = differenceTime - remainingTimeToRequestNewToken;
      enableTimeoutToRequestNewToken(timeToRequestNewToken);
      return
    }

    if (!tokenExpirationTimeRef.current) {
      console.error('Could not get token expiration time');
      return
    }

    localStorage.setItem("tokenExpirationDateStored", new Date(currentDate.getTime() + tokenExpirationTimeRef.current));
    handleSessionManager();
  }

  const onScreenClick = () => {
    if (isReadyToFetchToken) {
      handleFetchNewToken();
    }
  }

  const enableTimeoutToRequestNewToken = (timingTime) => {
    timeoutToFetchNewTokenRef.current = getTimeoutToFetchNewToken(timingTime);
  }

  const enableTimeoutToShowTokenRetrievalModal = (timingTime) => {
    timeoutToShowTokenRetrievalModalRef.current = getTimeoutToShowTokenRetrievalModal(timingTime);
  }

  const enableTimeoutToLogout = (timingTime) => {
    timeoutToLogoutRef.current = getTimeoutToLogout(timingTime);
  }

  const handleFetchNewToken = async () => {
    setIsReadyToFetchToken(false);
    setIsFetchTokenModalShowing(false);
    await onFetchNewToken();
  }

  const onFetchNewToken = async () => {
    const newTokenRequest = new NewClassRequest(
      `${process.env.REACT_APP_URL_API_LARAVEL}/auth/refresh_token`, "post", null, {}
    );
    const newTokenResponse = await newTokenRequest.executeRequest();
    if (Number(newTokenResponse.code) !== 200) {
      NotificationManager.error("No se ha podido actualizar el token de sesión");
      return
    }
    const { authentication_token_02 } = Cookies.get();
    Cookies.remove("authentication_token_02", { path: "/" });
    handleFreeMemorySessionManager();
    Cookies.set("authentication_token_02", newTokenResponse.response.refreshToken);
    const decodedToken = getDecodedJwt(newTokenResponse.response.refreshToken);
    tokenExpirationTimeRef.current = decodedToken.exp - decodedToken.iat;

    const currentDate = new Date();
    localStorage.setItem("tokenExpirationDateStored", new Date(currentDate.getTime() + tokenExpirationTimeRef.current));
    handleSessionManager();
  }

  const handleLogOut = async () => {
    Loader.show(true);
    await logOut(null, handleFreeMemorySessionManager);
    Loader.show(false);
  }

  return (
    {
      handleSessionManager,
      handleFreeMemorySessionManager,
      tokenExpirationTimeRef,
      isFetchTokenModalShowing,
      setIsFetchTokenModalShowing,
      handleLogOut,
      handleFetchNewToken,
      countdownTimeToLogout,
      onScreenClick,
    }
  )
}

export default useSessionManager