import React from 'react'
import styles from './RegisterLoginButtons.module.css'
import SecondaryButton from '../button/secondaryButton/SecondaryButton'
import PrimaryButton from '../button/primaryButton/PrimaryButton'

const RegisterLoginButtons = ({
  children,
  className = '',
  ...restOfProps
}) => {
  return (
    <div className={`${styles.registerLoginButtons} ${className}`} {...restOfProps}>
      <a href="https://ciudadano.cjj.gob.mx/" target="_blank">
        <SecondaryButton>
          Ingresa
        </SecondaryButton>
      </a>
      <a href="https://ciudadano.cjj.gob.mx/register" target="_blank">
        <PrimaryButton>
          Regístrate
        </PrimaryButton>
      </a>
      {children}
    </div>
  )
}

export default RegisterLoginButtons