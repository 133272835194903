import React, { useCallback, createContext, useState, useContext } from 'react'

const PreviewPdfModalContext = createContext();
PreviewPdfModalContext.displayName = 'PreviewPdfModalContext';

const PreviewPdfModalProvider = ({ children }) => {

  const [isPreviewPdfModalShowing, setIsPreviewPdfModalShowing] = useState(false);
  const [documentDataToPreview, setDocumentDataToPreview] = useState('');

  const setPdfDataToPreviewAndShowPreviewPdfModal = useCallback((pdfPathToPreview) => {
    setDocumentDataToPreview(pdfPathToPreview);
    setIsPreviewPdfModalShowing(true);
  }, []);

  const hidePreviewPdfModal = useCallback(() => setIsPreviewPdfModalShowing(false), []);

  const providerValue = {
    isPreviewPdfModalShowing,
    documentDataToPreview,
    setPdfDataToPreviewAndShowPreviewPdfModal,
    hidePreviewPdfModal,
  }

  return (
    <PreviewPdfModalContext.Provider value={providerValue}>
      {children}
    </PreviewPdfModalContext.Provider>
  )
}

export const usePreviewPdfModalContext = () => {
  const context = useContext(PreviewPdfModalContext)
  if (typeof context === 'undefined') {
    throw new Error('To use usePreviewPdfModalContext you must wrap inside a PreviewPdfModalProvider')
  }
  return context
}

export default PreviewPdfModalProvider