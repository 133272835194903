export const judgmentSubtypes = {
  interlocutory: {
    name: 'interlocutory',
    displayName: 'Interlocutoria',
  },
  definitive: {
    name: 'definitive',
    displayName: 'Definitiva',
  },
  second_instance: {
    name: 'secondInstance',
    displayName: 'Segunda instancia',
  },
}