import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { getFileExtensionFromName } from '../../../../services/dataFormatting/getFileExtensionFromName';
import ToggleButton from '../../../helpers_components/toggleButton/ToggleButton';
import TextTooltip from '../../../helpers_components/tooltip/TextTooltip';
import ElectronicSignatureTable from './componets/ElectronicSignatureTable';
import { useElectronicSignatureProcessContext } from './context/ElectronicSignatureProcessContext';
import CredentialsModal, { SignatureInputFile } from './credentialsModal/CredentialsModal';
import AddOrChangeMultiSignatureButton from '../../../buttons/addOrChangeMultiSignatureButton/AddOrChangeMultiSignatureButton';

const ElectronicSignatureProcess = ({
  onPreviousToAddFileToSign = async () => true,
  onCreatePkcs7 = () => { },
  disableSingleSignature = false,
  disableMultiSignature = false,
}) => {

  const {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal,
    onAddElectronicSignature,
    isMultipleSignature,
    setIsMultipleSignature,
    isReadyToCreatePkcs7,
    documentToSign,
    onAddDocumentToSign,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    isThereASignature,
  } = useElectronicSignatureProcessContext();

  useEffect(() => {
    if (disableSingleSignature) {
      setIsMultipleSignature(true);
    } else if (disableMultiSignature) {
      setIsMultipleSignature(false);
    }
  }, [disableSingleSignature, disableMultiSignature]);

  const doesMultipleSignatureToogleEnabled = (!disableSingleSignature && !disableMultiSignature);

  const handleOnAddDocumentToSign = async (files) => {
    let filExtension = '';
    try {
      filExtension = getFileExtensionFromName(files[0].name);
    } catch (error) {
      NotificationManager.error(error.message);
    }

    if (!['pdf', 'PDF', 'doc', 'DOC', 'docx', 'DOCX'].includes(filExtension)) {
      NotificationManager.error("El documento debe ser de tipo pdf, doc o docx");
      return
    }
    const hasPreviousActivityBeenCompleted = await onPreviousToAddFileToSign(files);
    if (!hasPreviousActivityBeenCompleted) {
      return
    }
    onAddDocumentToSign(files)
  }

  return (
    <div>
      {
        doesMultipleSignatureToogleEnabled
        &&
        <ToggleButton
          onChange={(event) => setIsMultipleSignature(event.target.checked)}
          name='isMultipleSignature'
          size={'medium'}
        >
          Habilitar multifirma
        </ToggleButton>
      }

      <div className={'mb-3'}>
        <AddOrChangeMultiSignatureButton
          canSignatureBeChanged={(isThereASignature && !isMultipleSignature)}
          onClick={() => setIsElectronicSignatureModalSowing(true)}
        />
      </div>

      {
        (Boolean(electronicSignaturesList.length && isMultipleSignature) || (!isMultipleSignature && Boolean(singleElectronicSignature)))
        &&
        <>
          <p className='m-0'>
            {isMultipleSignature ? 'Lista de Firmantes' : 'Firmante'}
          </p>
          <ElectronicSignatureTable
            electronicSignaturesList={electronicSignaturesList}
            singleElectronicSignature={singleElectronicSignature}
            isMultipleSignature={isMultipleSignature}
            onDelteElectronicSignatureById={onDelteElectronicSignatureById}
          />
        </>
      }

      <div>
        <TextTooltip text={'El nombre del documento a firmar sólo debe incluir números, espacios o letras sin acentos'}>
          <SignatureInputFile
            label="* Documento a firmar"
            setFile={handleOnAddDocumentToSign}
            id={`documentToSign`}
            accept={['.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX']}
            fileName={documentToSign ? documentToSign.name : ''}
            isMultiFiles
          />
        </TextTooltip>
      </div>
      <Button
        onClick={onCreatePkcs7}
        disabled={!isReadyToCreatePkcs7}
      >
        Firmar
      </Button>
      {
        isElectronicSignatureModalSowing
        &&
        <CredentialsModal
          isModalSowing={isElectronicSignatureModalSowing}
          onCloseModal={onCloseModal}
          addElectronicSignatureHandle={onAddElectronicSignature}
          isMultipleSignature={isMultipleSignature}
        />
      }
    </div>
  )
}

export default ElectronicSignatureProcess
