export const getLocaleDateString = (date, displayHour = false, hour12 = true) => {
  return new Date(date).toLocaleDateString('es-MX', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: displayHour ? 'numeric' : undefined,
    minute: displayHour ? 'numeric' : undefined,
    hour12
  })
}

export const checkIfStringOnlyIncludesLettersNumbersOrSpaces = (stringToValidate) => {
  var regExpValidation = new RegExp(/^[A-Za-z0-9\s]+$/g);
  return regExpValidation.test(stringToValidate);
}