import Cookies from "js-cookie";

//**** METODO DE MULTIFIRMA****
import { handleUploadFile, handleUpload, _filesToSign, _Keys, AddSigner, Sign, SignMultifirma, PKCS7creado, extFileUpload } from './multifirma';
import { CertificateRequest } from './clasesFirma';
import { Request } from '../components/classes/Request';

const { names_03, paternal_surname_03 } = Cookies.get();

var extArchivoCargado = ''; //almacenamos la extensiondel archivo a firmar
var listFirmas = [];  // array de objetos de las firmas almacenadas en sql
var archivoOriginal = null; // almacena el documento en haxadecimal (el que se va a generar la evidencia)
var puedeGenerarEvidencia = false; //no se usa
var pkcs7 = null; // documento que contiene la evidencia criptografica multifirma
var nombPKSC7completo = '';  //nombre completo del documento pkcs7
var FileSignedB64 = ''; //Almacena el documento que responde el server de la firma electronica en Base64 (evidencia critpgrafica)

/*************** METODOS PARA MULTIFIRMA  *****************/
export const SelccionarArchivo = async (event) => {
  const isValidFile = await handleUploadFile(event)
  if (isValidFile) {
    archivoOriginal = _filesToSign[0].HexFile;
    //Obtenemos la extension del archivo que se convirtio en Hexadecimal
    extArchivoCargado = extFileUpload;
    return true
  }
  return false
};

export const SeleccionarPfxMultiSignature = (filePFX) => {
  handleUpload(filePFX).then((isValidFile) => {
    if (isValidFile) {
    }
  });
};

export const getMultiSignatureItem = async (claveFirel) => {
  const isValidPassword = await AddSigner(claveFirel);
  if (!isValidPassword) {
    return false;
  }

  let firma = {
    firma: _Keys[_Keys.length - 1]
  }

  delete firma.firma.Pfx;

  if (
    !firma.firma.CertPEM ||
    !firma.firma.FileName ||
    !firma.firma.CertPEM ||
    !firma.firma.PrivatePEM ||
    !firma.firma.Status
  ) {
    return false
  }

  return { ...firma }
}

export const guardarFirmaMultiSignature = async (claveFirel, formData, signature, multipleSignaturePath) => {
  const { saveData, addSigner } = multipleSignaturePath;
  const isValidPassword = await AddSigner(claveFirel);
  let firma = {};

  if (!signature) {
    if (!isValidPassword) {
      return false
    }

    firma = {
      firma: _Keys[_Keys.length - 1]
    }

    delete firma.firma.Pfx;

  } else {
    firma = signature
  }

  if (
    !firma.firma.CertPEM ||
    !firma.firma.FileName ||
    !firma.firma.CertPEM ||
    !firma.firma.PrivatePEM ||
    !firma.firma.Status
  ) {
    return false
  }

  const isFirstSignature = !!formData;
  const urlPath = isFirstSignature ? saveData : addSigner;
  const requestData = isFirstSignature ? formData : { ...firma };

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/${urlPath}`,
    "post",
    null,
    requestData
  );

  try {
    const saveSignatureResponse = await request.executeRequest();
    if (!saveSignatureResponse || saveSignatureResponse.code !== 200) {
      return false
    }
    if (isFirstSignature) {
      return { ...saveSignatureResponse, firma }
    }
    return true
  } catch (error) {
    console.error("From MultiSignature error de guardar firma", error)
    return false
  }
};

export const obtenerFirmas = async (documentId) => {

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/signers/${documentId}`,
    "get",
    null,
    null
  );

  try {
    const signersListResponse = await request.executeRequest();
    let responseFirmas = Object.values(signersListResponse.data.data.data);
    const filteredSignList = responseFirmas.filter((sign) => typeof sign === 'object');
    const formattedSignList = filteredSignList.map((sign) => JSON.parse(sign.data));

    listFirmas = formattedSignList

    //verificamos cuantas firmas se han registrado si hay por lo menos una firma continuamos
    //esto dependiero del proceso
    if (listFirmas.length >= 1) {
      puedeGenerarEvidencia = true;
    }
  } catch (error) {
    console.error("Error al obtener firmas: ", error)
  } finally {
    return puedeGenerarEvidencia
  }
};

export const setSignaturesList = (electronicSignaturesList) => {
  const formattedElectronicSignaturesList = electronicSignaturesList.map((signatureItem) => signatureItem.firma);
  listFirmas = formattedElectronicSignaturesList

  //verificamos cuantas firmas se han registrado si hay por lo menos una firma continuamos
  //esto dependiero del proceso
  if (listFirmas.length >= 1) {
    puedeGenerarEvidencia = true;
  }
  return puedeGenerarEvidencia
}

//con esta funcion obtenemnos el documento que los usuario visualizaran antes de firmar
export const obtenerArchivoAFirmar = async (documentToSign) => {
  let IsFileReceivedSuccessfully = false;
  console.log({documentToSign})
  try {
    const response = await fetch(documentToSign.filePath);
    if (!response || !response.status || response.status !== 200) {
      console.error('Error al obtener el archivo')
      return IsFileReceivedSuccessfully
    }
    const responseToBlob = await response.blob();
    responseToBlob.name = documentToSign.fileName;
    responseToBlob.lastModified = new Date();

    const file = new File([responseToBlob], responseToBlob.name, {
      type: documentToSign.fileType,
    });

    const isValidFile = await SelccionarArchivo({ target: { files: [file] } });
    if (isValidFile) {
      IsFileReceivedSuccessfully = true
    }
  } catch {
    console.error('Error al obtener el archivo')
    IsFileReceivedSuccessfully = false
  } finally {
    return IsFileReceivedSuccessfully
  }
};

export const getPkcs7 = async () => {
  if (!puedeGenerarEvidencia) {
    console.error("No hay firmas para generar la evidencia");
    return false;
  }

  const isPkcs7Created = await SignMultifirma(listFirmas, archivoOriginal, "documento");
  if (!isPkcs7Created) {
    return false;
  }
  var fecha = new Date();
  pkcs7 = PKCS7creado;

  return pkcs7
};

export const signMultipleSignature = async (documentToSign) => {
  if (!puedeGenerarEvidencia) {
    console.error("No hay firmas para generar la evidencia");
    return false;
  }

  const isPkcs7Created = await SignMultifirma(listFirmas, archivoOriginal, "documento");
  if (!isPkcs7Created) {
    return false;
  }

  var fecha = new Date();
  pkcs7 = PKCS7creado;
  // nombPKSC7completo = "pkcs7_" + fecha.valueOf() + ".pdf";
  nombPKSC7completo = documentToSign.fileName;
  const wasPkcs7Shipped = await creaEvidencia(pkcs7, documentToSign.documentId
  );
  return wasPkcs7Shipped
};

const creaEvidencia = async (pkcs7, documentId) => {
  //POR AQUI UN LOADING  Y UN MENSAJE DE ESTABLECIENDO CONEXION
  let wasPkcs7Shipped = false
  if (!pkcs7) {
    return wasPkcs7Shipped
  }

  CertificateRequest.CertificateType = 3;
  CertificateRequest.Pkcs7Original = pkcs7;
  CertificateRequest.FileName = nombPKSC7completo;
  CertificateRequest.UserName = `${names_03} ${paternal_surname_03}`;

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signature_documents/signature_end/${documentId}`,
    "post",
    null,
    CertificateRequest
  );

  try {
    const signDocumentResponse = await request.executeRequest();
    if (signDocumentResponse.code !== 200) {
      return wasPkcs7Shipped
    }
    //guardamos el response que viene en Base64
    FileSignedB64 = signDocumentResponse.data.PdfBase64;
    wasPkcs7Shipped = true
    // saveTextAsFilePDF(nombPKSC7completo);
  } catch (error) {
    console.error(error);
  }
  finally {
    return wasPkcs7Shipped
  }

};

const saveTextAsFilePDF = () => {
  //var fileB64 = FileSignedB64
  var fecha = new Date();
  let blob = b64toBlob(FileSignedB64, 'application/pdf', 512);
  //let fileNameToSaveAs = "documento_firmado_" + fecha.valueOf() + ".pdf";
  let fileNameToSaveAs = nombPKSC7completo
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  //link.setAttribute('target' ,'_blank');
  link.setAttribute('download', fileNameToSaveAs);
  document.body.appendChild(link);
  link.click();
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const postData = async (url = '', data = {}) => {
  let authenticationToken = Cookies.get("authentication_token_02") ?
    String(Cookies.get("authentication_token_02")) :
    btoa(process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authenticationToken
    },
    body: JSON.stringify(data)
  });
  return response;
}

export const getTemplateFileName = (templateName, templateId, ExpedientId) => {
  if(!templateName || !templateId || !ExpedientId){
    return 'unknown_name';
  }
  const templateNameFormatted = templateName.split(' ').join('_');
  const ExpedientIdFormatted = String(ExpedientId).split('/').join('');
  return `${templateNameFormatted}${ExpedientIdFormatted}${templateId}`;
}