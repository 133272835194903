import React, { useMemo, useState, createContext, useContext } from 'react';
import { firmar, firmarSat, SeleccionarArchivo, SeleccionarArchivoEFirma } from '../../../../../services/electronicSignature';
import { SelccionarArchivo, setSignaturesList, getPkcs7 as getPkcs7MultipleSign } from '../../../../../services/multipleElectronicSignature';

const ElectronicSignatureProcessContext = createContext();
ElectronicSignatureProcessContext.displayName = 'ElectronicSignatureProcessContext';

const ElectronicSignatureProcessProvider = ({ children }) => {

  const [isElectronicSignatureModalSowing, setIsElectronicSignatureModalSowing] = useState(false);
  const [electronicSignaturesList, setElectronicSignaturesList] = useState([]);
  const [isMultipleSignature, setIsMultipleSignature] = useState(false);
  const [documentToSign, setDocumentToSign] = useState(null);
  const [singleElectronicSignature, setSingleElectronicSignature] = useState(null);
  const [isThereASignature, setIsThereASignature] = useState(false);

  const isReadyToCreatePkcs7 = useMemo(() => {
    const hasDocumentToSignBeenUploaded = Boolean(documentToSign);
    if (isMultipleSignature) {
      const isThereMoreThanOneSignature = electronicSignaturesList.length > 1;
      return (isThereMoreThanOneSignature && hasDocumentToSignBeenUploaded);
    }
    const hasSingleElectronicSignatureBeenAdded = Boolean(singleElectronicSignature);
    return (hasSingleElectronicSignatureBeenAdded && hasDocumentToSignBeenUploaded);
  }, [electronicSignaturesList, isMultipleSignature, documentToSign, singleElectronicSignature]);

  const onCloseModal = () => {
    setIsElectronicSignatureModalSowing(false);
  }

  const onAddElectronicSignature = (electronicSignatureToAdd) => {
    const { multiSign, singleSign } = electronicSignatureToAdd;
    if (Boolean(multiSign)) {
      setElectronicSignaturesList((previousState) => [...previousState, { ...multiSign }]);
    }
    setIsThereASignature(true);
    setSingleElectronicSignature(singleSign);
    onCloseModal();
  }

  const onDelteElectronicSignatureById = (id) => {
    const newElectronicSignaturesList = electronicSignaturesList.filter((signatureItem) => signatureItem.id !== id);
    setElectronicSignaturesList(newElectronicSignaturesList);
  }

  const onAddDocumentToSign = async (document) => {
    setDocumentToSign(document[0]);
  }

  const handleLoadDocumentToSignToElectronicSignature = async () => {
    if (!documentToSign) {
      throw new Error('Error uploading file to sign');
    }
    const isMultipleSignUploaded = await SelccionarArchivo({ target: { files: [documentToSign] } });
    const isSingleSingEfirmaUploaded = await SeleccionarArchivoEFirma(documentToSign);
    const isSingleSingFirelUploaded = await SeleccionarArchivo(documentToSign);
    return (isMultipleSignUploaded && isSingleSingEfirmaUploaded && isSingleSingFirelUploaded)
  }

  const getPkcs7SingleSign = async () => {
    if (singleElectronicSignature.signaturetype === 'sat') {
      return await firmarSat(singleElectronicSignature.password);
    }
    return await firmar(singleElectronicSignature.password);
  }

  const getPkcs7 = async () => {
    const isDocumentToSignLoaded = await handleLoadDocumentToSignToElectronicSignature();
    if (!isDocumentToSignLoaded) {
      throw new Error('File to sign has not been uploaded');
    }
    let pkcs7 = '';
    if (isMultipleSignature) {
      setSignaturesList(electronicSignaturesList);
      pkcs7 = await getPkcs7MultipleSign();
    } else {
      pkcs7 = await getPkcs7SingleSign();
    }
    return pkcs7;
  }

  const onResetSignatures = () => {
    setElectronicSignaturesList([]);
    setSingleElectronicSignature(null);
  }

  const contextValue = {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal,
    onAddElectronicSignature,
    isMultipleSignature,
    setIsMultipleSignature,
    getPkcs7,
    isReadyToCreatePkcs7,
    documentToSign,
    setDocumentToSign,
    onAddDocumentToSign,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    setSingleElectronicSignature,
    isThereASignature,
    onResetSignatures,
  }

  return (
    <ElectronicSignatureProcessContext.Provider value={contextValue}>
      {children}
    </ElectronicSignatureProcessContext.Provider>
  )
}

export const useElectronicSignatureProcessContext = () => {
  const context = useContext(ElectronicSignatureProcessContext);
  if (typeof context === 'undefined') {
    throw new Error('To use useElectronicSignatureProcessContext you must wrap inside a ElectronicSignatureProcessProvider');
  }
  return context
}

export default ElectronicSignatureProcessProvider