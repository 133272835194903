import React from 'react'
import styles from './CollapsibleControl.module.css'

const CollapsibleControl = ({
  className = '',
  isCollapsibleActive = false,
  ...restOfProps
}) => {

  const faIcon = isCollapsibleActive ? 'fas fa-times' : 'fas fa-ellipsis-h';

  return (
    <div className={`${styles.collapsibleControl} ${className}`} {...restOfProps}>
      <i className={faIcon}></i>
    </div >
  )
}

export default CollapsibleControl