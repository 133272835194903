import React, { createContext, useContext, useEffect, useState } from 'react'
import { getExpedientNotification, getExpedientNotificationsIds } from '../../../login/login_logic';
import { getIsAuth } from '../../../../services/getIsAuth';

const ExpedientNotificationContext = createContext();
ExpedientNotificationContext.displayName = 'ExpedientNotificationContext';

const ExpedientNotificationProvider = (props) => {
  const isAuth = getIsAuth();
  const [showModal, setShowModal] = useState(false);
  const [currentNotifications, setCurrentNotifications] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(0);
  const [pauseCarousel, setPauseCarousel] = useState(false);

  const handleExpedientNotification = async () => {
    if (!isAuth)
      return
    const expedientNotificationIds = await getExpedientNotificationsIds();
    if (expedientNotificationIds.length > 0 && !showModal) {
      await getExpedientNotification(
        expedientNotificationIds,
        setShowModal,
        setCurrentNotifications,
      );
    }
  }

  useEffect(() => {
    handleExpedientNotification();
  }, [showModal, isAuth]);

  const contextValue = {
    showModal,
    setShowModal,
    currentNotifications,
    setCurrentNotifications,
    currentDocument,
    setCurrentDocument,
    pauseCarousel,
    setPauseCarousel,
    handleExpedientNotification,
  }

  return (
    <ExpedientNotificationContext.Provider {...props} value={contextValue} />
  )
}

export default ExpedientNotificationProvider

export const useExpedientNotificationContext = () => {
  const context = useContext(ExpedientNotificationContext);

  if (typeof context === 'undefined')
    throw new Error('useExpedientNotificationContext must be wrapped in a ExpedientNotificationProvider');

  return context;
}