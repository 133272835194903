import React from 'react';
import { getLocaleDateString } from '../../../../../services/dataFormatting/getLocaleDateString';

const ChangeCourtTable = ({ expedientId, changeCourtTableData = [] }) => {
  return (
    <>
      {
        Boolean(changeCourtTableData.length)
        &&
        changeCourtTableData.map((tableElement) => (
          <div
            key={tableElement.id}
            className='mb-4'
            style={{ border: String(expedientId) === String(tableElement.electronic_id_destiny) ? '3px solid #dbd9d9' : 'unset' }}
          >
            <table className="table table-sm table-bordered mb-0">
              < tbody >
                <tr>
                  <th className="caratule_heading pR-15">Juzgado origen:</th>
                  <td className="caratule_content pL-15">{tableElement.court_name_origin || 'N/A'}</td>
                </tr>
                <tr>
                  <th className="caratule_heading pR-15">Expediente origen:</th>
                  <td className="caratule_content pL-15">{tableElement.expedient_number_origin || 'N/A'}</td>
                </tr><tr>
                  <th className="caratule_heading pR-15">Juzgado destino:</th>
                  <td className="caratule_content pL-15">{tableElement.court_name_destiny || 'N/A'}</td>
                </tr><tr>
                  <th className="caratule_heading pR-15">Expediente destino:</th>
                  <td className="caratule_content pL-15">{tableElement.expedient_number_destiny || 'N/A'}</td>
                </tr><tr>
                  <th className="caratule_heading pR-15">Fecha:</th>
                  <td className="caratule_content pL-15">{getLocaleDateString(tableElement.created_at.date) || 'N/A'}</td>
                </tr>
              </tbody>
            </table >
          </div >
        ))
      }
    </>
  )
}

export default ChangeCourtTable