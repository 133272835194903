import axios from "axios";

export const initForm = {
  customKey: "",
  errors: [],
  styleClassAlert: false,
  visible: false
};

//Send the code confirmation from email to validate in api if is valid
export const postConfirmationString = async customKey => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_API_LARAVEL}/track_process`,
      {
        token: process.env.REACT_APP_TOKEN,
        custom_key: customKey
      }
    );
    return response;
  } catch (err) {
    const response = err.response.data;
    return {
      code: response.code,
      message: response.data.error
    };
  }
};

//validate function to export in component
export const validateStringConfirm = async customKey => {
  if (customKey.trim().length === 64) {
    const response = await postConfirmationString(customKey);
    if (response.data.code === 200) {
      return {
        ...response,
        code: 200
      };
    } else {
      return {
        code: response.data.code,
        customKey: "",
        errors: [
          {
            message: response.data.data.message
          }
        ],
        title: "Error interno",
        visible: true
      };
    }
  } else {
    return {
      customKey: "",
      code: 401,
      errors: [
        {
          message: "El codigo de trámite que intenta ingresar no es valido."
        }
      ],
      title: "Error de validación",
      visible: true
    };
  }
};
