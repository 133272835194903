import React from "react";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";

const ExpedientsSearcher = (props) => {
  const {
    placeholderSearch,
    placeholderSearchButton,
    placeholderCleanButton,
    helperContextual,
    executeSearch,
    cleanSearch,
    isSearching,
    isCleaning,
    setSearch,
    search,
    classNameButtonsSearch,
    hasRulesToSearch,
    rulesToSearch,
    leyend = '',
  } = props;

  const onEnterKey = (e) => {

    if (e.key === "Enter" && search.length > 0) {
      executeSearch();
    }
  };

  const mouseOver = (e) => {
    e.target.style.backgroundColor = "#1d78ff";
  };

  const mouseOut = (e) => {
    e.target.style.backgroundColor = "#02cc98";
  };

  const onSearchInputChange = (e) => {
    if (hasRulesToSearch && rulesToSearch.test(e.target.value)) {
      setSearch(e.target.value);
    } else if (!hasRulesToSearch) setSearch(e.target.value);
  }

  return (
    <>
      <section
        className="searcherContainer"
      >
        <Row>
          <Col xs={12} sm={5}>
            <h4 className="mB-30 mT-30 w-100">
              {leyend}
            </h4>
          </Col>
          <Col ms={12} sm={7}>
            <div className="d-flex flex-column flex-xl-row align-items-end mt-4" style={{ gap: '5px' }}>
              <FormControl
                placeholder={placeholderSearch}
                value={search}
                onKeyDown={onEnterKey}
                onChange={onSearchInputChange}
                id="searcher"
                style={{ fontSize: '1rem' }}
              />
              <Button
                variant="outline-secondary"
                onClick={executeSearch}
                disabled={isSearching || isCleaning}
                onMouseOver={mouseOver}
                onMouseOut={mouseOut}
                style={{ backgroundColor: "#02cc98", color: "#FAFAFA", whiteSpace: 'nowrap' }}
              >
                <i className="ti-search" style={{ pointerEvents: "none" }} /> {placeholderSearchButton}
              </Button>
            </div>
            <small>{helperContextual}</small>
          </Col>
        </Row>

        <div className="clearFloat" />
        <div className="clearFloat" />
        <div className={classNameButtonsSearch ? classNameButtonsSearch : "buttonsSearch"} >
          <Button
            variant="secondary"
            className="px-4 mT-20 btn-responsive"
            onClick={() => cleanSearch()}
            disabled={isCleaning || isSearching}
            style={isSearching ? { display: "none" } : null}
          >
            {isCleaning ? "Limpiando..." : placeholderCleanButton}
          </Button>
        </div>

      </section>
    </>
  );

};

export default ExpedientsSearcher;