import React from 'react'
import ExpedientConsultationStateProvider from './context/expedientConsultationState/ExpedientConsultationState'
import '../../commonStylesOJB.css';
import MainLayout from '../../components/mainLayout/MainLayout';
import styles from './ExpedientConsultation.module.css'
import ExpedientDetails from '../../components/expedientDetails/ExpedientDetails';
import PrimaryCard from '../../components/cards/primaryCard/PrimaryCard';
import useExpedientConsultationState from './hooks/useExpedientConsultationState';

const ExpedientConsultation = (props) => {

  const expedientConsultationState = useExpedientConsultationState(props);
  const contextValue = {
    ...props,
    ...expedientConsultationState,
  }

  const { handleDownloadDocuments } = expedientConsultationState;

  return (
    <ExpedientConsultationStateProvider value={contextValue}>
      <MainLayout>
        <div className={styles.ExpedientDetailsWrapper}>
          <ExpedientDetails />
        </div>
        <div className={styles.primaryCardWrapper}>
          <PrimaryCard onDownloadButtonClick={handleDownloadDocuments} >
            Documentos
          </PrimaryCard>
        </div>
      </MainLayout>
    </ExpedientConsultationStateProvider>
  )
}

export default ExpedientConsultation