import Cookies from "js-cookie";
import { downloadFromABlob } from "./downloadFromABlob";

export const downloadFileWithAuthorizationHeader = async (path = '', fileName = 'file', fileExtension = 'pdf') => {
  try {
    const authenticationToken = Cookies.get("authentication_token_02");
    const optionsRequest = {
      method: 'GET',
      headers: { 'Authorization': authenticationToken },
    }

    const response = await fetch(path, optionsRequest);

    const isACorrectResponse = response.headers.get('Content-Type').startsWith(`application/${fileExtension}`);

    if (!isACorrectResponse) {
      throw new Error('The response does not include the file with the provided extension');
    }

    const fileBlob = await response.blob();
    downloadFromABlob(fileBlob, fileName, fileExtension);

  } catch (error) {
    throw new Error(error.message);
  }
}