import React, { useMemo, useState } from 'react';
import uuidv4 from 'uuid/v4';

const defaultToggleButtonStyles = {
  backgroundColor: 'var(--secondary)',
  display: 'block',
  width: '100px',
  height: '45px',
  borderRadius: '45px',
  position: 'relative',
  transition: 'background-color .5s',
  margin: 0,
  cursor: 'pointer',
}

const checkedToggleButtonStyles = {
  ...defaultToggleButtonStyles,
  backgroundColor: 'var(--success)',
}

const defaultKnobStyles = {
  width: '50px',
  height: '50px',
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto 0',
  backgroundColor: 'white',
  borderRadius: '50%',
  border: '1px solid var(--secondary)',
  transition: 'transform .5s'
}

const checkedKnobStyles = {
  ...defaultKnobStyles,
  transform: 'translate(100%)',
}

const getScale = (size) => {
  switch (size) {
    case 'small':
      return '0.5'
    case 'medium':
      return '0.7'
    default:
      return '1'
  }
}

const ToggleButton = ({
  children,
  onChange = () => { },
  initialState = false,
  size = 'large',
  ...restOfProps
}) => {
  const [isChecked, setIsChecked] = useState(initialState);
  const toggleButtonId = useMemo(() => uuidv4(), []);

  const onInputChange = (event) => {
    onChange(event);
    setIsChecked(event.target.checked);
  }

  return (
    <div style={{
      width: '100%',
      margin: 0,
    }}>
      <label htmlFor={toggleButtonId}>
        {children}
      </label>
      <div style={{ transform: `scale(${getScale(size)})`, transformOrigin: 'left top' }}>
        <label
          htmlFor={toggleButtonId}
          style={isChecked ? checkedToggleButtonStyles : defaultToggleButtonStyles}
        >
          <div
            style={isChecked ? checkedKnobStyles : defaultKnobStyles}
          >
          </div>
        </label >
      </div>
      <input
        checked={isChecked}
        onChange={onInputChange}
        type="checkbox"
        id={toggleButtonId}
        {...restOfProps}
        style={{
          display: 'none'
        }}
      />
    </div >
  )
}

export default ToggleButton