import React, { createContext, useContext } from 'react';
import { UseEmptyContextError } from '../../../../../../services/customErrors/useEmptyContextError/useEmptyContextError';

const ExpedientConsultationStateContext = createContext(null);

const ExpedientConsultationStateProvider = (props) => {
  return (
    <ExpedientConsultationStateContext.Provider {...props} />
  )
}

export const useExpedientConsultationStateContext = () => {
  const context = useContext(ExpedientConsultationStateContext);

  if (context === null) {
    throw new UseEmptyContextError('ExpedientConsultationState');
  }

  return context
}

export default ExpedientConsultationStateProvider