import React from 'react'
import styles from './DefaultButton.module.css'

const DefaultButton = ({
  className = '',
  children,
  ...restOfProps
}) => {
  return (
    <button className={`${styles.button} ${className}`} {...restOfProps}>
      {children}
    </button>
  )
}

export default DefaultButton