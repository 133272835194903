import React, { useState, useContext } from 'react';
import { Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import Plain from '../../layout/containers/Plain';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import {
  getJudicialParties,
  getCourtsByJudicialPartyId,
  renderErrorsByInputName,
  fieldsValidation
} from '../../search_for_lawyers/search_lawyers_logic'
import Dropzone from 'react-dropzone';
import TableUploadManyFiles from '../../helpers_components/TableAddProofs';
import LoaderContext from '../../layout/shared/loader_context';
import axios from 'axios';

const InsertPromotions = (props) => {
  const loader = useContext(LoaderContext);
  const [judicialParties, setJudicialParties] = useState([]);
  const [errors, setErrors] = useState({});
  const [courts, setCourts] = useState([]);
  const [insert, setInsert] = useState({});
  const [judicialParty, setJudicialParty] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [typeFile, setTypeFile] = useState({});
  const [imageURL, setImageURL] = useState('');
  const [dataUploadEvidences, setDataUploadEvidences] = useState(null);


  const onChangeJudicialParty = async (judicialPartySelected) => {
    if (judicialPartySelected['value'] !== null) {
      setJudicialParty(judicialPartySelected);
      setInsert({ ...insert, judicial_party: judicialPartySelected });
      await getCourtsByJudicialPartyId(
        judicialPartySelected['value'],
        setCourts
      );
    }
  };

  const onChangeCourt = async (courtSelected) => {
    if (courtSelected['value'] !== null) {
      setInsert({ ...insert, court: courtSelected });
    }
  };

  const onDrop = (files) => {
    const reader = new FileReader();

    if (files.length > 0) {
      if (files[0]['type'].includes('image')) {
        setImageURL(URL.createObjectURL(files[0]));
        setTypeFile({ type: 'image', name: files[0]['name'] });
      }
      else setTypeFile({ type: 'document', name: files[0]['name'] });

      reader.onload = (event) => {
        delete errors['file'];
        setInsert({ ...insert, file: files[0] });
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const reloadComponent = async (message, title, time) => {
    NotificationManager.success(
      message,
      title,
      time
    );
    setTypeFile({});
    setJudicialParty({});
    setImageURL('');
    setDataUploadEvidences(null);
    setInsert({});
    setCourts([]);
    setJudicialParties([]);
  };

  const handleSubmitInsert = async () => {
    const requiredFields = insert['hasProofs'] === 'no' ? 
      ['judicial_party', 'court', 'alias', 'file', 'promoter', 'hasProofs', 'expedient_number'] :
      ['judicial_party', 'court', 'alias', 'file', 'promoter', 'hasProofs', 'evidence_file', 'expedient_number'];
    const frontValidation = fieldsValidation(requiredFields, insert);
    if (typeof frontValidation === 'object') {
      setErrors(frontValidation);
      NotificationManager.error(
        '',
        'Existen errores, por favor revisa los campos marcados en rojo',
        7500
      );
    } else {
      await requestInsert();
    }
  };

  const requestInsert = async () => {
    loader.show(true);
    // PRIMERO ENVIAMOS Y CREAMOS EL DOCUMENTO
    let formData = new FormData();
    formData.append('document_type', 'promotion');
    const keysDataSend = ['judicial_party', 'court', 'alias', 'file', 'promoter', 'hasProofs', 'expedient_number', 'comment'];
    
    for( let dataKey of keysDataSend ) {
      if (dataKey === 'court') formData.append(dataKey, insert[dataKey]['value'])
      else formData.append(dataKey, insert[dataKey] ? insert[dataKey] : '' );
    };

    if (dataUploadEvidences === null) {
      const responseDocumentSave = await axios({
        method: "post",
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/upload`,
        data: formData
      });
      if (
        insert['hasProofs'] === 'si' &&
        responseDocumentSave && responseDocumentSave.data &&
        responseDocumentSave.data.code === 200 && responseDocumentSave.data.data &&
        responseDocumentSave.data.data.documentExpedient &&
        responseDocumentSave.data.data.promotion_id 
      ) {
        setDataUploadEvidences({
          promotion_id: responseDocumentSave.data.data.promotion_id,
          documentUpload: responseDocumentSave.data.data.documentExpedient,
          change: new Date().getTime()
        });
      } else if (
        insert['hasProofs'] === 'no' &&
        responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
        responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
        typeof responseDocumentSave.data.data.documentExpedient === 'object'
      ) {
        await reloadComponent(
          'La promoción ha sido insertada al expediente de manera correcta...',
          '¡Excelente!',
          5000
        );
      } else {
        if (responseDocumentSave.data.code === 422 && responseDocumentSave.data.data.message['file']) {
          NotificationManager.error(
            responseDocumentSave.data.data.message['file'],
            'Existen errores:',
            10000
          );
          setErrors({ ...errors, file: responseDocumentSave.data.data.message['file'] })
        } else if (responseDocumentSave.data.code === 404) {
          NotificationManager.error(
            responseDocumentSave.data.data.message,
            'Existen errores:',
            10000
          );
        } else {
          NotificationManager.error(
            'Algún dato que has ingresado es incorrecto, por favor revisa tu información',
            'Existen errores:',
            10000
          );
        }
      }
    } else {
      let newDataUploadEvidence = {...dataUploadEvidences};
      newDataUploadEvidence['change'] = new Date().getTime();
      setDataUploadEvidences(newDataUploadEvidence);
    }
    loader.show(false);
  };

  const checkErrors = (name) => {
    if (errors[name]) {
      return { color: 'red' };
    } else return {};
  };

  return (
    <>
      <Plain {...props} noHeader={true} noFooter={true}>
        <Container fluid={true} className='min-height-100vh bg-size-cover'
          style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/img/login_wallpaper.png)' }}>
          <Container>
            <Row style={{ minHeight: '100vh' }}>
              <Col sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}
                className='mY-30'>
                <Card className='mY-40@sm+'>
                  <Card.Header>
                    <Container>
                      <Row>
                        <Col xs={12} md={{ span: 10, offset: 1 }} >
                          <h3 className='mb-0 text-center'>
                            Presentar promoción a un expediente
                          </h3>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label>
                        <b style={checkErrors('judicial_party')}>* Partido Judicial</b>
                      </Form.Label>
                      <Select
                        options={judicialParties}
                        onChange={selected => {
                          delete errors['judicial_party'];
                          onChangeJudicialParty(selected);
                        }}
                        placeholder='Selecciona un partido judicial'
                        value={judicialParty && judicialParty['label'] ? judicialParty : ''}
                        onFocus={async e => getJudicialParties(setJudicialParties, setIsLoading)}
                        isLoading={isLoading}
                        loadingMessage={() => 'Cargando datos...'}
                        noOptionsMessage={() => 'No hay datos que mostrar'}
                      />
                      {renderErrorsByInputName(errors, 'judicial_party')}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        <b style={checkErrors('court')}>* Juzgado</b>
                      </Form.Label>
                      <Select
                        options={courts}
                        onChange={selected => {
                          delete errors['court'];
                          onChangeCourt(selected);
                        }}
                        placeholder='Selecciona un juzgado'
                        value={insert && insert['court'] ? insert['court'] : ''}
                        noOptionsMessage={insert['judicial_party'] ? () => 'No hay datos que mostrar' : () => 'Antes selecciona un partido judicial'}
                      />
                      {renderErrorsByInputName(errors, 'court')}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        <b style={checkErrors('expedient_number')}>* Número de expediente:</b>
                      </Form.Label>
                      <Form.Control
                        placeholder={'Ingresa el número de expediente a buscar, ejemplo CJJ01/2020'}
                        className='inputinserterToLawyer'
                        onChange={(e) => {
                          if (/^[a-zA-Z0-9/]*$/.test(e.target.value)) {
                            delete errors['expedient_number'];
                            setInsert({ ...insert, expedient_number: e.target.value });
                          }
                          else setErrors({ ...errors, expedient_number: ['Sólo son permitidos caracteres alfanuméricos y/o diagonal'] })
                        }}
                        value={insert['expedient_number'] ? insert['expedient_number'] : ''}
                      />
                      {renderErrorsByInputName(errors, 'expedient_number')}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        <b style={checkErrors('file')}>* Promoción:</b>
                      </Form.Label>

                      <Dropzone
                        onDrop={(files) => onDrop(files)}
                        accept={['.png', '.PNG', '.pdf', '.PDF', '.jpg', '.JPG', '.jpeg', '.JPEG']}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className='file-nilo-wrapper' {...getRootProps()}>
                            { '* Agrega un archivo a la promoción'}
                            <input style={{ display: 'inline!important' }} className='form-control' {...getInputProps()} />
                          </div>
                        )}
                      </Dropzone>
                      {renderErrorsByInputName(errors, 'file')}

                      <div
                        className={typeFile['type'] ? 'file-upload-wrapper' : 'd-none'}
                      >
                        <img
                          className={typeFile['type'] === 'document' ? 'd-block' : 'd-none'}
                          src={process.env.PUBLIC_URL + '/img/document.svg'}
                          alt='Documento'
                        />
                        <img
                          className={typeFile['type'] === 'image' ? '' : 'd-none'}
                          src={imageURL}
                          alt='Imagen'
                          width='50%'
                        />
                      </div>
                      <small style={{ float: 'right' }}>{typeFile['name']}</small>
                      <br />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        <b style={checkErrors('comment')}>Comentarios:</b>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          delete errors['comment'];
                          setInsert({ ...insert, comment: e.target.value });
                        }}
                        value={insert['comment'] ? insert['comment'] : ''}
                        as='textarea'
                        rows='5'
                        aria-label='Comentarios'
                        maxLength={255}
                        placeholder='Agrega comentarios referente a esta promoción'
                      />
                      {renderErrorsByInputName(errors, 'comment')}
                    </Form.Group>

                    <Form.Group>
                      <br/>
                      <Form.Label>
                        <b style={checkErrors('promoter')}>* Promotor:</b>
                      </Form.Label>
                      <Form.Control
                        value={insert['promoter'] ? insert['promoter'] : ''}
                        aria-label='Nombre del promovente'
                        onChange={(e) => {
                          delete errors['promoter'];
                          setInsert({ ...insert, promoter: e.target.value });
                        }}
                        type='text'
                        placeholder='Agrega el nombre del promotor de esta promoción'
                      />
                      {renderErrorsByInputName(errors, 'promoter')}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        <b style={checkErrors('alias')}>* Etiqueta:</b>
                      </Form.Label>
                      <Form.Control
                        value={insert['alias'] ? insert['alias'] : ''}
                        onChange={(e) => {
                          delete errors['alias'];
                          setInsert({ ...insert, alias: e.target.value });
                        }}
                        placeholder='Agrega una etiqueta para identificar esta promoción'
                      />
                      {renderErrorsByInputName(errors, 'alias')}
                    </Form.Group>


                    <Form.Group >
                      <Form.Label>
                        <b style={checkErrors('hasProofs')}>* ¿Quieres agregar anexos?</b>
                      </Form.Label>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Form.Check
                          type='radio'
                          label={'Si'}
                          checked={insert['hasProofs'] === 'si' ? true : false}
                          id={'si'}
                          value='si'
                          onChange={(e) => {
                            if (insert['expedient_number']) {
                              delete errors['hasProofs'];
                              setInsert({ ...insert, hasProofs: e.target.value });
                            } else {
                              setErrors({
                                ...errors,
                                hasProofs:['Primero agrega el número de expediente'],
                                expedient_number:['Para agregar anexos primero agrega el número de expediente'],
                              });
                            }
                          }}
                        />
                        <Form.Check
                          type='radio'
                          label={'No'}
                          checked={insert['hasProofs'] === 'no' ? true : false}
                          id={'no'}
                          value='no'
                          onChange={(e) => {
                            delete errors['hasProofs'];
                            setInsert({ ...insert, hasProofs: e.target.value });
                          }}
                        />
                      </div>
                      {renderErrorsByInputName(errors, 'hasProofs')}
                    </Form.Group>

                    {
                      insert['hasProofs'] === 'si' && insert['expedient_number'] ?
                        <>
                          <Form.Group controlId='formBasicFile'>
                            <Form.Label>
                              <b style={checkErrors('evidence_file')}>* Anexos:</b>
                            </Form.Label>
                            <TableUploadManyFiles
                              acceptFiles={['.png', '.PNG', '.pdf', '.PDF', '.jpg', '.JPG', '.jpeg', '.JPEG']}
                              getFiles={(files) => {
                                if (files.length > 0) {
                                  setInsert({
                                    ...insert,
                                    evidence_file: files
                                  });
                                  delete errors['evidence_file'];
                                }
                                else {
                                  delete insert['evidence_file'];
                                }
                              }}
                              reloadDataComponent={reloadComponent}
                              dataUploadEvidences={dataUploadEvidences}
                              expedientNumber={insert['expedient_number'].replace(/\//g, '-') || 'undefined'}
                              errorsHeredated={errors}
                              isSuperPosisionated={false}
                            />
                            {renderErrorsByInputName(errors, 'file')}
                          </Form.Group>
                        </> : ''
                    }
                  </Card.Body>
                  <Card.Footer>
                      <Button
                        block 
                        variant='primary' 
                        onClick={() => handleSubmitInsert()} 
                      >
                        <b>
                          Presentar promoción a un expediente
                        </b>
                      </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </Plain>
    </>
  );
};

export default InsertPromotions;
