import React from 'react'
import { useSessionManagerContext } from '../../../context/sessionManagerContext/sessionManagerContext';
import ModalComponent from '../../helpers_components/ModalComponent';
import { Button } from 'react-bootstrap';

const SessionManagerModal = () => {

  const {
    isFetchTokenModalShowing,
    setIsFetchTokenModalShowing,
    handleLogOut,
    handleFetchNewToken,
    countdownTimeToLogout,
  } = useSessionManagerContext();

  return (
    <ModalComponent
      header={(<h3 className="text-center">Alerta</h3>)}
      body={(
        <div>
          <h5 className="text-center">
            Tu sesión esta próxima a vencer en: <span className="font-weight-bolder">{countdownTimeToLogout} segundos</span>
          </h5>
          <h4 className="text-center">
            ¿Desea continuar usando el sistema?
          </h4>
        </div>
      )}
      footer={(
        <>
          <Button
            variant="secondary"
            onClick={handleLogOut}
          >
            Cerrar sesión ({countdownTimeToLogout})
          </Button>
          <Button
            variant="success"
            onClick={handleFetchNewToken}
          >
            Continuar
          </Button>
        </>
      )}
      show={isFetchTokenModalShowing}
      onHide={setIsFetchTokenModalShowing}
      isHeaderCenterJustified
    />
  )
}

export default SessionManagerModal