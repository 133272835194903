import React from 'react';
import { usePreviewPdfModalContext } from '../context/previewPdfModalContext';
import { Button } from 'react-bootstrap';
import ModalComponent from '../../helpers_components/ModalComponent';
import RenderPdfDocument from '../../helpers_components/multifile/RenderDocumentPDF';
import { useLoaderContext } from '../../layout/shared/loader_context';
import { downloadFile } from '../expedient_details/expedient_details_logic';

const DownloadPreviewDocumentModal = ({
  document,
  documentType,
  isDownloadDocumentButtonShowing,
}) => {

  const {
    isPreviewPdfModalShowing,
    hidePreviewPdfModal,
    setPdfDataToPreviewAndShowPreviewPdfModal,
  } = usePreviewPdfModalContext();

  const loader = useLoaderContext();
  const handleOpenModalButtonClassName = isDownloadDocumentButtonShowing ? "btn btn-info" : "btn btn-secondary no-event-click";

  const handleDownloadDocument = () => {
    downloadFile(document.file_path, loader);
  }

  const footerModalProps = {
    onDownloadDocumentButtonClick: handleDownloadDocument,
    onCloseButtonClick: hidePreviewPdfModal,
    isDownloadDocumentButtonShowing: isDownloadDocumentButtonShowing,
  }

  return (
    <>
      <button
        onClick={setPdfDataToPreviewAndShowPreviewPdfModal}
        className={handleOpenModalButtonClassName}
        title={'Descargar / Visualizar'}
      >
        <i className="ti-notepad"/>
      </button>

      <ModalComponent
        header={documentType}
        body={<PreviewPdfBodyModal documentPath={document.file_path} documentName={documentType} />}
        footer={<FooterModal {...footerModalProps} />}
        show={isPreviewPdfModalShowing}
        canClose={true}
        onHide={hidePreviewPdfModal}
        size={'lg'}
      />
    </>
  )
}

const FooterModal = ({
  onDownloadDocumentButtonClick,
  onCloseButtonClick,
  isDownloadDocumentButtonShowing,
}) => {
  return (
    <>
      {
        Boolean(isDownloadDocumentButtonShowing)
        &&
        <Button variant={'primary'} onClick={onDownloadDocumentButtonClick}>
          <i class="fas fa-file-download"></i> Descargar
        </Button>
      }
      <Button variant={'secondary'} onClick={onCloseButtonClick}>
        Cerrar
      </Button>
    </>
  )
}

const PreviewPdfBodyModal = ({ documentPath, documentName }) => {
  return (
    <div className={'d-flex flex-column align-items-center'}>
      <h4 className={'text-center'}>{documentName}</h4>
      <RenderPdfDocument
        doc={documentPath}
        currentDocument={documentPath}
      />
    </div>

  )
}

export default DownloadPreviewDocumentModal