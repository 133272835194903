import React from "react";
import { Link } from 'react-router-dom';
import { Grid } from "react-flexbox-grid";
import { useExpedientNotificationContext } from "../../modals/expedientNotificationModal/context/ExpedientNotificationContext";

const TopMenu = props => {

  const {
    handleExpedientNotification = () => { }
  } = useExpedientNotificationContext();

  return (
    <Grid fluid={true} className="principal-nav">
      <Grid>
        <ul className="nav nav-tabs">
          <li className="nav-item mr-1" onClick={handleExpedientNotification}>
              <Link to="/home" className={"nav-link " + (props.pageConfig.active === 'home' ? 'active' : '')}>
                <i className="ti-home mr-1" />Home
              </Link>
          </li>
          <li className="nav-item mr-1" onClick={handleExpedientNotification}>
              <Link to="/formalities" className={"nav-link " + (props.pageConfig.active === 'my_formalities' ? 'active' : '')}>
                <i className="ti-id-badge mr-1" />Proyectos de envío
              </Link>
          </li>
          <li className="nav-item mr-1" onClick={handleExpedientNotification}>
              <Link to="/procedure-list" className={"nav-link " + (props.pageConfig.active === 'all_formalities' ? 'active' : '')}>
                <i className="ti-agenda mr-1" />Trámites disponibles
              </Link>
          </li>
          <li className="nav-item-black mr-1" onClick={handleExpedientNotification}>
              <Link to="/my-expedients" className={"nav-link " + (props.pageConfig.active === 'my_expedients' ? 'active' : '')}>
                <i className="ti-agenda mr-1" />Mis expedientes
              </Link>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default TopMenu;
