import { getDocumentNameWithExtensionInLowercase } from "./getDocumentNameWithExtensionInLowercase";

export const getFileWithExtensionInLowercase = (file) => {
  const isAValidFile = file instanceof File;
  if (!isAValidFile) {
    throw new Error('File is not a File instance');
  }

  const documentNameWithExtensionInLowercase = getDocumentNameWithExtensionInLowercase(file.name);
  const fileWithExtensionInLowercase = new File([file], documentNameWithExtensionInLowercase, { type: file.type });

  return fileWithExtensionInLowercase;
}