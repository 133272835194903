import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Table, Badge, Form } from "react-bootstrap";

const NotificationOffline = props => {
  return (
    <section className="notification-panel">
      <Row center="xs">
        <Col xs={9} style={{ padding: "30px" }}>
          <div style={{ padding: "20px" }}>
            <h1>Notificaciones de Trámites</h1>
            <h5 style={{ float: "right" }}>
              Pendientes de lectura <Badge variant="danger">1</Badge>
            </h5>
          </div>
          <Table striped bordered hover className="shadow-light">
            <thead>
              <tr>
                <th>No.Trámite</th>
                <th>Fecha Inicio</th>
                <th>Estatus</th>
                <th>Descargar Archivo</th>
                <th>Marcar como leido</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ background: "#dc3545", color: "white" }}>
                <td>66567857</td>
                <td>12/03/2019</td>
                <td>Falta documentación para realizar registro</td>
                <td>
                  <div>
                    <i className="fas fa-file-download" />
                  </div>
                </td>
                <td className="td-action">
                  <div>
                    <Form.Check type="checkbox" />
                  </div>
                </td>
              </tr>
              <tr style={{ background: "#dc3545", color: "white" }}>
                <td>66567857</td>
                <td>12/03/2019</td>
                <td>Falta documentación para realizar registro</td>
                <td>
                  <div>
                    <i className="fas fa-file-download" />
                  </div>
                </td>
                <td className="td-action">
                  <div>
                    <Form.Check type="checkbox" />
                  </div>
                </td>
              </tr>
              <tr style={{ background: "#dc3545", color: "white" }}>
                <td>66567857</td>
                <td>12/03/2019</td>
                <td>Falta documentación para realizar registro</td>
                <td>
                  <div>
                    <i className="fas fa-file-download" />
                  </div>
                </td>
                <td className="td-action">
                  <div>
                    <Form.Check type="checkbox" />
                  </div>
                </td>
              </tr>
              <tr style={{ background: "#dc3545", color: "white" }}>
                <td>66567857</td>
                <td>12/03/2019</td>
                <td>Falta documentación para realizar registro</td>
                <td>
                  <div>
                    <i className="fas fa-file-download" />
                  </div>
                </td>
                <td className="td-action">
                  <div>
                    <Form.Check type="checkbox" />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </section>
  );
};

export default NotificationOffline;
