import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalMultiFile = (props) => {
  const {
    header,
    body,
    footer,
    show,
    canClose,
    onHide,
    scroll,
    dialogClassName
  } = props;


  return (
    <>
      <Modal 
        dialogClassName={dialogClassName || ""} 
        style={scroll ? { display: 'block' } : null} 
        show={show} 
        onHide={canClose ? () => onHide(false) : () => onHide(true)}
      >
        <Modal.Header className={"bg-complement-3"}>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={scroll ? { height: 'auto', maxHeight: '60vh', overflowY: 'auto' } : null}>{body}</Modal.Body>
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalMultiFile;