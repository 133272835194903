import React, { useEffect } from "react";
import { NewClassRequest } from "../../classes/NewClassRequest"
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { Button, Form } from "react-bootstrap";
import { renderErrorsByInputName } from "../../search_for_lawyers/search_lawyers_logic";
import Dropzone from "react-dropzone";
import Select from "react-select";
import TableAddProofs from "../../helpers_components/TableAddProofs";
import ElectronicSignatureProcessModalContent from "./electronicSignature/ElectronicSignatureProcessModalContent";
import {useElectronicSignatureProcessContext} from "../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext";
import TextTooltip from "../../helpers_components/tooltip/TextTooltip";
import { getFileNameWithoutExtension } from "../../../services/dataFormatting/getFileNameWithoutExtension";
import { checkIfStringOnlyIncludesLettersNumbersOrSpaces } from "../../../services/dataFormatting/getLocaleDateString";
import { contentListToOmit } from "../../../data/constants/contentListToOmit";
import _ from "lodash";
import { judgmentSubtypes } from "./services/judgmentSubtypes";
import { showAlertForDocumentToBeSignedNotAllowed } from "./services/showAlertForDocumentToBeSignedNotAllowed";
import { getCanTypeOfDocumentBeSigned } from "./services/getCanTypeOfDocumentBeSigned";
import { getIsAValidMaxFileSize } from "./services/getIsAValidMaxFileSize";
import { getFileWithExtensionInLowercase } from "./services/getFileWithExtensionInLowercase";

export const columns = [
  {
    dataField: "actions",
    text: "Documentos",
    sort: true,
    style: { maxWidth: "250px", minWidth: "120px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "filename",
    text: "Nombre",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "alias",
    text: "Etiqueta",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "document_type_translated",
    text: "Tipo",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "created_date",
    text: "Creación",
    sort: true,
    style: { maxWidth: "80%", minWidth: "100px", textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment",
    text: "Comentarios",
    sort: true,
    style: { maxWidth: "250px", minWidth: "100px", textAlign: "justify" },
    headerStyle: { textAlign: "center" }
  }
];

export const getDocumentsByExpedientNumber = async (
  expedient_number,
  setDataExpedient,
  setDataDocuments,
  setTextNotDataDocuments,
  setTotalDocuments,
  sizePerPage,
  page,
  loader,
  setStateDocuments,
  setCommentModal,
  setShowCommentModal,
  textToSearch = ''
) => {
  const searchType = textToSearch ? 'find_documents' : 'documents';
  page = textToSearch ? 1 : page;
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/${searchType}/${expedient_number}/${sizePerPage}?page=${page}&search=${textToSearch}`,
    "get",
    null,
    {}
  );
  const responseDocumentsExpedient = await request.executeRequest();
  if (responseDocumentsExpedient.code === 403) {
    NotificationManager.error(
      'No cuentas con permiso para accesar al expediente',
      '',
      5000
    );
    Cookies.set("citizen_unauthorized", true);
  }
  if (responseDocumentsExpedient.code === 200) {
    if (responseDocumentsExpedient.response.expedient && responseDocumentsExpedient.response.expedient) {
      const documents = await formattedDocuments(
        responseDocumentsExpedient.response,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal
      );
      const expedient = {
        ...responseDocumentsExpedient.response.expedient,
        date: responseDocumentsExpedient.response.date ? new Date(responseDocumentsExpedient.response.date).toLocaleDateString('es-MX', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        }) : null
      };

      setDataExpedient(expedient);
      setDataDocuments(documents);
      setTotalDocuments(responseDocumentsExpedient.response.total);
      setTextNotDataDocuments(
        responseDocumentsExpedient.response.documents.length > 0 ?
          "" :
          responseDocumentsExpedient.response.message
      );
    }
  }
};

export const getDocumentTypeToDisplay = (documentType = '', judgmentType = '') => {

  const judgmentTypeToDisplay = _.get(judgmentSubtypes, `${judgmentType}.displayName`, '');

  const defaultValue = '';

  const documentTypeToDisplay = {
    [defaultValue]: defaultValue,
    agreement: 'Acuerdo',
    promotion: 'Promoción',
    judgment: `Sentencia ${judgmentTypeToDisplay}`,
    notification: 'Notificación',
    demand: 'Demanda',
    writ: 'Oficio',
    subpoena: 'Citatorio',
    diligence: 'Acta de diligencia',
    recordAudience: 'Constancia de celebración de audiencia',
    constancy: 'Constancia',
    amparo: 'Demanda de amparo',
    audience: 'Audiencia',
  }

  return documentTypeToDisplay[documentType];
};

const formattedDocuments = (
  dataResponse,
  loader,
  setStateDocuments,
  setCommentModal,
  setShowCommentModal
) => {
  let { documents } = dataResponse;

  return documents.map((document) => {

    let documentTypeToDisplay = getDocumentTypeToDisplay(document.document_type, document.judgment_type);

    const timeIndicator = checkExpiredTime(document.expired);

    document["document_type_translated"] = documentTypeToDisplay;
    document["created_date"] = new Date(document["created_at"].replace(/-/g, "/")).toLocaleDateString('es-MX', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
    document["actions"] = (
      <div className="procedures-actions">
        <button
          onClick={() => downloadFile(document["file_path"], loader)}
          className={"btn btn-info"}
          title={documentTypeToDisplay}
        >
          <i className="ti-notepad" />
        </button>

        {document["evidence_path"] &&
          <button
            onClick={() => setStateDocuments(document['id'], false)}
            className={"btn button-see-evidence-documents mL-10"}
            title="Documento de pruebas"
          >
            <i className="ti-files" />
          </button>
        }

        {document['notification_path'] &&
          <button
            onClick={() => setStateDocuments(document['id'], true)}
            className={"btn button-see-notifications-documents mL-10"}
            title="Notificaciones"
          >
            <i className="fas fa-file-invoice" />
          </button>
        }
      </div>
    );

    document['time_indicator'] = (<span className={"dot-" + timeIndicator} />);
    document['filename'] = getFormattedText(document['filename']);
    document['alias'] = getFormattedText(document['alias']);
    document['full_comment'] = document['comment'];

    const commentIsAnInvalidValue = contentListToOmit.includes(document.comment);
    if (commentIsAnInvalidValue) {
      document.comment = '';
    } else {
      document['comment'] = document.comment.length > 110
      ? (
        <div>
          {
            document.comment.slice(0, 110)}... <strong style={{ cursor: 'pointer' }} onClick={() => {
              setCommentModal(document.full_comment);
              setShowCommentModal(true);
            }}>Ver más</strong>
        </div>
      )
      : document.comment;
    }
      
    return document;
  });
};

export const downloadFile = (urlFile, loader) => {
  loader.show(true);
  let a = document.createElement('a');
  a.href = urlFile;
  a.download = true;
  a.target = "_blank";
  a.rel = "noopener noreferrer";

  a.click();
  loader.show(false);
};

const checkExpiredTime = (dataExpired) => {
  if (dataExpired.expired_at && dataExpired.warning) {
    const expiredDate = new Date(dataExpired.expired_at);
    const todayDate = dataExpired.answered_at ? new Date(dataExpired.answered_at) : new Date();

    const expired = new Date(expiredDate.setHours(0, 0, 0, 0));
    const today = new Date(todayDate.setHours(0, 0, 0, 0));
    const timeTranscurred = (expired - today) / 86400000;

    if (timeTranscurred >= dataExpired.warning) return 'green';
    if (timeTranscurred < dataExpired.warning && timeTranscurred >= 0) return 'yellow';
    if (timeTranscurred < 0) return 'red';
  }
  return 'white';
};

export const getEvidencesDocument = async (
  documentId,
  setDocumentsData,
  setShowDocuments
) => {

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/evidences/retrieve/${documentId}`,
    "get",
    null,
    {}
  );

  const responseEvidences = await request.executeRequest();
  if (
    responseEvidences.code === 200 &&
    responseEvidences.response &&
    responseEvidences.response.evidences
  ) {
    const { evidences } = responseEvidences.response;
    setDocumentsData(evidences);
    setShowDocuments(true);
  }
  else {
    setDocumentsData([]);
    setShowDocuments(true);
    NotificationManager.info(
      'No se encontraron anexos en esta promoción',
      '',
      3500
    );
  }
};

export const AddPromotionBodyModal = ({
  setErrors,
  setPromotion,
  errors,
  promotion,
  imageURL,
  onDrop,
  typePromotion,
  expedientNumber,
  reloadComponent,
  dataUploadEvidences,
  setIsAddSignatureModalShowing,
}) => {

  const { isElectronicSignatureModalSowing } = useElectronicSignatureProcessContext();

  useEffect(() => {
    setIsAddSignatureModalShowing(isElectronicSignatureModalSowing);
  }, [isElectronicSignatureModalSowing]);

  const {
    onAddDocumentToSign,
  } = useElectronicSignatureProcessContext();

  const onAddFileToSign = (files) => {
    const droppedFile = files[0];
    const doesDocumentExist = Boolean(droppedFile);
    if (!doesDocumentExist) {
      showAlertForDocumentToBeSignedNotAllowed();
      return
    }

    const canTypeOfDocumentBeSigned = getCanTypeOfDocumentBeSigned(droppedFile.type);
    if (!canTypeOfDocumentBeSigned) {
      showAlertForDocumentToBeSignedNotAllowed();
      return
    }

    const isAValidMaxFileSize = getIsAValidMaxFileSize(droppedFile);
    if (!isAValidMaxFileSize) {
      NotificationManager.error("El archivo que intenta cargar al sistema, supera los 18MB de tamaño permitido. Intente con un documento de menor tamaño");
      return
    }

    const fileNameWithoutExtension = getFileNameWithoutExtension(droppedFile.name);
    const isAValidFilename = checkIfStringOnlyIncludesLettersNumbersOrSpaces(fileNameWithoutExtension);
    if (!isAValidFilename) {
      NotificationManager.warning('El nombre del documento sólo debe incluir números, espacios o letras sin acentos');
      return
    }

    const fileToSave = getFileWithExtensionInLowercase(droppedFile);

    onDrop([fileToSave], 1);
    onAddDocumentToSign([fileToSave]);
  }

  return (
    <div>

      <div className="border-bottom border-secondary mb-3 pb-3">
        <p className="m-0">
          * Firma Electrónica
        </p>
        <ElectronicSignatureProcessModalContent />
      </div>

      <Form>
        <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control
            onChange={(e) => {
              setErrors({ ...errors, alias: [] });
              setPromotion({ ...promotion, alias: e.target.value });
            }}
            placeholder="Agrega una etiqueta para identificar esta promoción"
            className={errors["alias"] && !promotion["alias"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "alias")}
          <Form.Text className={errors["alias"] ? "d-n" : "text-muted"}>
            Ingresa una etiqueta para identificar esta promoción
          </Form.Text>
        </Form.Group>

        <>
          <Form.Group controlId="formBasicFile">
            <Form.Label>* Documento:</Form.Label>
            <TextTooltip className="w-100" text={'El nombre del documento a firmar sólo debe incluir números, espacios o letras sin acentos'}>
              <Dropzone
                onDrop={onAddFileToSign}
                accept={[".doc", ".DOC", ".pdf", ".PDF", ".docx", ".DOCX"]}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="file-nilo-wrapper" {...getRootProps()}>
                    {'* Selecciona un documento'}
                    <input style={{ display: "inline!important" }} className="form-control" {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </TextTooltip>
          </Form.Group>
          <div className="classHelperContextual" >
            <small>El documento deber ser de tipo PDF, DOC Ó DOCX</small>
          </div>
          {renderErrorsByInputName(errors, "file")}
          <div
            className={typePromotion["type"] ? "" : "d-none"}
          >
            <div className={"file-upload-wrapper"}>
              <img
                className={typePromotion["type"] === "document" ? "d-block" : "d-none"}
                src={process.env.PUBLIC_URL + "/img/document.svg"}
                alt="Documento"
              />
              <img
                className={typePromotion["type"] === "image" ? "" : "d-none"}
                src={imageURL}
                alt="Imagen"
                width="50%"
              />
            </div>
            <small style={{ float: "right" }}>{typePromotion["name"]}</small>
            <br />
          </div>
        </>

        <Form.Group>
          <br />
          <Form.Label>* Nombre del promovente:</Form.Label>
          <Form.Control
            value={promotion["promoter"] ? promotion["promoter"] : ""}
            aria-label="Nombre del promovente"
            onChange={(e) => {
              setPromotion({ ...promotion, promoter: e.target.value });
              setErrors({ ...errors, promoter: [] });
            }}
            type="text"
            className={errors["promoter"] && !promotion["promoter"] ? "is-invalid border-error" : ""}
          />
          {renderErrorsByInputName(errors, "promoter")}
        </Form.Group>

        <Form.Group >
          <Form.Label>* ¿Quieres agregar anexos?</Form.Label>
          <Form.Check
            type="radio"
            label={"Si"}
            checked={promotion['hasProofs'] === "si" ? true : false}
            id={"si"}
            value="si"
            onChange={(e) => {
              setPromotion({ ...promotion, hasProofs: e.target.value });
            }}
          />
          <Form.Check
            type="radio"
            label={"No"}
            checked={promotion['hasProofs'] === "no" ? true : false}
            id={"no"}
            value="no"
            onChange={(e) => {
              setPromotion({ ...promotion, hasProofs: e.target.value });
            }}
          />
          {renderErrorsByInputName(errors, "hasProofs")}
        </Form.Group>

        {
          promotion['hasProofs'] === 'si' ?
            <>
              <Form.Group controlId="formBasicFile">
                <Form.Label>* Anexos</Form.Label>
                <TableAddProofs
                  acceptFiles={[".png", ".PNG", ".pdf", ".PDF", ".jpg", ".JPG", ".jpeg", ".JPEG"]}
                  getFiles={(files) => {
                    if (files.length > 0) {
                      setPromotion({
                        ...promotion,
                        evidence_file: files
                      });
                      setErrors({
                        ...errors,
                        evidence_file: []
                      });
                    }
                    else {
                      delete promotion['evidence_file'];
                    }
                  }}
                  reloadDataComponent={reloadComponent}
                  dataUploadEvidences={dataUploadEvidences}
                  expedientNumber={expedientNumber}
                  errorsHeredated={errors}
                  isSuperPosisionated={true}
                />
              </Form.Group>
            </> : ""
        }
      </Form>
    </div>
  );
};

export const bodyModalDocuments = (documents) => {
  return (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        <tbody>
          <tr className="table-documents-modal">
            <th className="text-center">Etiqueta</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Enlace de descarga</th>
          </tr>
          {
            documents.map((doc, index) => (
              <tr key={index}>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={doc['alias']}>{doc['alias']}</td>
                <td style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }} className="text-center" title={doc['document_type']}>{doc['document_type']}</td>
                <td style={{ paddingTop: "5px" }} className="text-center">
                  <a download target="_blank" rel="noopener noreferrer" href={doc['file_path'] + "?token=" + process.env.REACT_APP_TOKEN}>
                    <Button
                      variant="primary"
                      style={{
                        marginLeft: "10px",
                        fontSize: "0.85rem"
                      }}
                      size="sm"
                    >
                      Archivo digital <i className="ti-download" title={"Descargar " + doc['alias']} />
                    </Button>
                  </a>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export const footerModalDocuments = (setCloseModal) => {

  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()} >Cerrar</Button>
    </>
  );
};

export const FooterModal = ({setCloseModal, handleSubmitPromotion}) => {

  const {
    getPkcs7,
    isReadyToCreatePkcs7,
  } = useElectronicSignatureProcessContext();

  const onCreatePkcs7 = async () => {
    if (!isReadyToCreatePkcs7) {
      return
    }

    let pkcs7 = '';
    try {
      pkcs7 = await getPkcs7();
      if (!Boolean(pkcs7)) {
        return
      }
      handleSubmitPromotion(pkcs7);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()}>Cancelar</Button>
      <Button variant="primary" onClick={onCreatePkcs7} disabled={!isReadyToCreatePkcs7} >
        <i className="fas fa-user-edit"></i> Firmar
      </Button>
    </>
  )
};

export const bodyModalNotifications = (documents) => {
  const notifications = documents.map((document) => {
    return document.expedient_notifications;
  });

  return (
    <div>
      <table className="table table-collapsed table-bordered mT-10">
        <tbody>
          <tr className="table-documents-modal">
            <th className="text-center">Nombre</th>
            <th className="text-center">Correo</th>
            <th className="text-center">Correo leído</th>
            <th className="text-center">Correo enviado</th>
            <th className="text-center">Enlace de descarga</th>
          </tr>
          {
            notifications.map((notifArr, index) => (
              Boolean(notifArr.length)
              ?
              notifArr.map((notif, i) => (
                <tr key={i}>
                  <td
                    style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                    className="text-center"
                  >
                    {notif['user_fullname'] || ''}
                  </td>
                  <td
                    style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                    className="text-center"
                  >
                    {notif['user_email'] || ''}
                  </td>
                  <td
                    style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                    className="text-center"
                  >
                    {notif['notified_at'] || ''}
                  </td>
                  <td
                    style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                    className="text-center"
                  >
                    {notif['sent_at'] || ''}
                  </td>
                  <td
                    className='center-horizontally-vertically-parent'
                    rowSpan={notifArr.length}
                    style={i === 0 ? null : { display: 'none' }}
                  >
                    <a
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      href={documents[index]['file_path'] + "?token=" + process.env.REACT_APP_TOKEN}
                      className="center-horizontally-vertically-child"
                    >
                      <Button
                        variant="primary"
                        style={{ fontSize: "0.85rem" }}
                        size="sm"
                      >
                        Archivo digital <i className="ti-download" title={"Descargar " + documents[index]['alias']} />
                      </Button>
                    </a>
                  </td>
                </tr>
              ))
              :
                <tr key={index}>
                  <td
                    style={{ paddingTop: "5px", fontSize: "0.8rem", maxWidth: "140px" }}
                    className="text-center"
                    colSpan={4}
                  >
                    {'Sin notificación electrónica'}
                  </td>
                  <td
                    className='center-horizontally-vertically-parent'
                  >
                    <a
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      href={documents[index].file_path + "?token=" + process.env.REACT_APP_TOKEN}
                      className="center-horizontally-vertically-child"
                      title={"Descargar " + _.defaultTo(documents[index].alias, '')}
                    >
                      <Button
                        variant="primary"
                        style={{ fontSize: "0.85rem" }}
                        size="sm"
                      >
                        Archivo digital <i className="ti-download"/>
                      </Button>
                    </a>
                  </td>
                </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export const footerModalNotifications = (setCloseModal) => {
  return (
    <>
      <Button variant="secondary" onClick={() => setCloseModal()} >Cerrar</Button>
    </>
  );
};

export const selectStylesDefault = {
  placeholder: (provided, state) => ({
    ...provided,
    color: '#c1c1c1'
  }),
  control: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057',
        backgroundColor: '#e9ecef',
        border: '1px solid #c1c1c1'
      };
    }
    else return { ...provided }
  },
  singleValue: (provided, state) => {
    if (state.isDisabled) {
      return {
        ...provided,
        color: '#495057'
      };
    }
    else return { ...provided }
  }
};

export const optionsDocument = [
  {
    value: 'Copia simple',
    label: 'Copia simple'
  },
  {
    value: 'Copia certificada',
    label: 'Copia certificada'
  },
  {
    value: 'Original',
    label: 'Original'
  }
];

export const bodyModalAddEvidenceFile = (
  setFileState, fileState, getRootProps,
  errors, setErrors, getInputProps
) => {

  return (
    <div>
      <Form>

        <Form.Group>
          <Form.Label>* Tipo de documento:</Form.Label>
          <Select
            options={optionsDocument}
            onChange={async (selected) => {

              setFileState({ ...fileState, evidence_type: selected["value"] });
              setErrors({ ...errors, evidence_type: [] });
            }}
            placeholder="Selecciona el tipo de documento"
            styles={selectStylesDefault}
          />
          {renderErrorsByInputName(errors, "evidence_type")}
        </Form.Group>

        <Form.Group>
          <Form.Label>* Etiqueta:</Form.Label>
          <Form.Control
            onChange={(e) => {
              setErrors({ ...errors, alias: [] });
              setFileState({ ...fileState, alias: e.target.value });
            }}
            placeholder="Agrega una etiqueta para identificar este documento"
          />
          {renderErrorsByInputName(errors, "alias")}
        </Form.Group>

        <Form.Group>
          <TextTooltip text={'Evitar archivos con caracteres especiales: puntos, guion bajo, barra inclinada, dos puntos, signos etc.'}>
            <Form.Label>* Anexo:</Form.Label>
          </TextTooltip>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Button style={{ fontWeight: "bold", fontSize: "14px" }} variant="primary" size="md" block>
                * Selecciona el anexo a subir
              </Button>
              <div className="classHelperContextual" >
                <small>El anexo deber ser de tipo PDF, PNG y JPG</small>
              </div>
            </div>
          </section>
          {renderErrorsByInputName(errors, "evidence_file")}

          {
            fileState["evidence_file"] && fileState["evidence_file"]["preview"] ?
              <>
                <div
                  className={fileState["evidence_file"] && fileState["evidence_file"]["preview"] ? "container-evidence-file" : "d-none"}
                >
                  <img
                    src={fileState["evidence_file"]["preview"]}
                    alt="Imagen"
                    width="50%"
                  />
                </div>
                <small style={{ float: "right" }}>{fileState["evidence_file"]["name"]}</small>
                <br />
              </> :
              ""
          }
        </Form.Group>

      </Form>
    </div>
  );
};

export const getFormattedText = (unformattedText) => {
  if (unformattedText != null) {
    return unformattedText.match(/.{1,25}/g).join(' ');
  }
  return null;
};

