import React from 'react'
import styles from './MainContent.module.css'

const MainContent = ({
  children,
  className = '',
  ...restOfProps
}) => {
  return (
    <div className={`${styles.mainContent} ${className}`} {...restOfProps}>
      {children}
    </div>
  )
}

export default MainContent