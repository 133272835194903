import { useMemo, useState } from 'react';
import uuidv4 from 'uuid/v4';
import { strCerPem } from '../../../../../services/eFirma';
import { getIsAValidEFirmaPassword, SeleccionarCer, SeleccionarKey, SeleccionarPfx } from '../../../../../services/electronicSignature';
import { getDetailsCer } from '../../../../../services/multifirma';
import { getMultiSignatureItem, SeleccionarPfxMultiSignature } from '../../../../../services/multipleElectronicSignature';

const electroniSignatures = {
  configuration: [
    {
      id: 1,
      type: 'sat',
      isEnabled: true,
      displayName: 'SAT',
      default: true,
      isMultiSign: false,
    },
    {
      id: 2,
      type: 'firel',
      isEnabled: true,
      displayName: 'Firel',
      default: false,
      isMultiSign: true,
      isMultiSignDisplayName: true,
      multiSignDisplayName: 'PFX',
    }
  ],

  getDefaultType: function (isMultiSign) {
    let electroniSignaturesListToFind = this.getTypes(isMultiSign);

    let faultType = electroniSignaturesListToFind.find((signature) => signature.default);
    if (faultType) {
      return faultType.type
    }

    faultType = electroniSignaturesListToFind[0];
    if (faultType) {
      return faultType.type
    }

    console.error('electroniSignatures.configuration is empy')
  },

  getTypes: function (isMultiSign) {
    const enabledSignatures = this.configuration.filter(signature => signature.isEnabled);
    if (isMultiSign) {
      const signaturesWithCustomDisplayName = enabledSignatures.map((signature) => {
        if (signature.isMultiSignDisplayName)
          return { ...signature, displayName: signature.multiSignDisplayName }
        return signature
      });
      return signaturesWithCustomDisplayName.filter(signature => signature.isMultiSign);
    }
    return enabledSignatures;
  },
}

const getInitialFormElectronicSignatureValues = (isMultipleSignature) => {
  return {
    signaturetype: electroniSignatures.getDefaultType(isMultipleSignature),
    password: '',
    id: uuidv4(),
    cer: null,
    key: null,
    pfx: null,
  }
}

const useElectronicSignatureManager = ({ isMultipleSignature }) => {

  const [formElectronicSignatureValues, setFormElectronicSignatureValues] = useState(getInitialFormElectronicSignatureValues(isMultipleSignature));
  const isAnElectronicSignatureFirelType = useMemo(() => formElectronicSignatureValues.signaturetype === 'firel', [formElectronicSignatureValues.signaturetype]);

  const onFormElectronicSignatureChange = ({ target: { name, dataset, value } }) => {
    let inputValue = value;
    let lastFormValues = formElectronicSignatureValues;
    if (name === 'signaturetype' && dataset.rbEventKey) {
      inputValue = dataset.rbEventKey;
      if (inputValue === formElectronicSignatureValues.signaturetype) {
        return
      }
    }
    setFormElectronicSignatureValues({ ...lastFormValues, [name]: inputValue });
  }

  const onSetElectronicSignatureKeyFile = (file, formName) => {
    switch (formName) {
      case 'cer':
        SeleccionarCer(file)
        break;
      case 'key':
        SeleccionarKey(file)
        break;
      case 'pfx':
        SeleccionarPfx(file)
        SeleccionarPfxMultiSignature(file);
        break;
    }
    setOnElectronicSignatureValues(formName, file);
  }

  const setOnElectronicSignatureValues = (name, value) => {
    onFormElectronicSignatureChange({ target: { name, value } })
  }

  const resetElectronicSignatureValues = () => {
    setFormElectronicSignatureValues(getInitialFormElectronicSignatureValues());
  }

  const onAddElectronicSignature = async (addElectronicSignatureHandle) => {
    let signatureItem = {};
    let multiSign = null;
    let singleSign = null;
    let signatureInfo = {};

    if (isAnElectronicSignatureFirelType) {
      signatureItem = await getMultiSignatureItem(formElectronicSignatureValues.password);
      if (!signatureItem) {
        throw new Error('Invalid credentials');
      }
      signatureInfo = { ...getDetailsCer(signatureItem.firma.CertPEM) };
      multiSign = {
        id: formElectronicSignatureValues.id,
        signatureInfo,
        ...signatureItem,
      }
    } else {
      const isAValidSatPassword = await getIsAValidEFirmaPassword(formElectronicSignatureValues.password);
      if (!isAValidSatPassword) {
        throw new Error('Invalid credentials');
      }
      signatureInfo = { ...getDetailsCer(strCerPem) };
    }
    singleSign = { ...formElectronicSignatureValues, signatureInfo };
    addElectronicSignatureHandle({ multiSign, singleSign });
  }

  return ({
    electroniSignatures,
    getInitialFormElectronicSignatureValues,
    formElectronicSignatureValues,
    isAnElectronicSignatureFirelType,
    onSetElectronicSignatureKeyFile,
    setOnElectronicSignatureValues,
    resetElectronicSignatureValues,
    onAddElectronicSignature,
    onFormElectronicSignatureChange,
  })
}

export default useElectronicSignatureManager