import React from 'react'
import { Table } from 'react-bootstrap'
import TextTooltip from '../../../../helpers_components/tooltip/TextTooltip'

const ElectronicSignatureTable = ({ electronicSignaturesList, singleElectronicSignature, isMultipleSignature, onDelteElectronicSignatureById }) => {
  return (
    <Table style={{ width: 'auto', fontSize: '11px', height: '1px' }}>
      <thead className='text-white bg-success'>
        <tr>
          <th>Nombre del firmante</th>
          <th>CURP</th>
          {
            isMultipleSignature
            &&
            <th>Acción</th>
          }
        </tr>
      </thead>
      <tbody>
        {
          Boolean(electronicSignaturesList.length && isMultipleSignature)
          &&
          electronicSignaturesList.map((electronicSignatureItem, index) => (
            <tr
              style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}
              key={electronicSignatureItem.id}
            >
              <TableItem>
                {electronicSignatureItem.signatureInfo.CommonName.toUpperCase()}
              </TableItem>
              <TableItem>
                {electronicSignatureItem.signatureInfo.CURPFirel.toUpperCase()}
              </TableItem>
              <TableItem noBackground>
                <TextTooltip text={'Eliminar firma electrónica'}>
                  <button
                    onClick={() => onDelteElectronicSignatureById(electronicSignatureItem.id)}
                    style={{
                      fontSize: '20px',
                      color: 'red',
                      backgroundColor: 'white',
                      border: 'none'
                    }}>
                    <i className="fas fa-trash-alt fa-xs"></i>
                  </button>
                </TextTooltip>
              </TableItem>
            </tr>
          ))
        }
        {
          (!isMultipleSignature && Boolean(singleElectronicSignature))
          &&
          <tr>
            <TableItem>
              {singleElectronicSignature.signatureInfo.CommonName.toUpperCase()}
            </TableItem>
            <TableItem>
              {singleElectronicSignature.signatureInfo.CURPFirel.toUpperCase()}
            </TableItem>
          </tr>
        }
      </tbody>
    </Table>
  )
}

const TableItem = ({ noBackground = false, children }) => {
  return (
    <td style={{ height: '100%', padding: 0 }} >
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: noBackground ? 'unset' : 'white',
          padding: '10px 20px'
        }} >
        <div>
          {children}
        </div>
      </div>
    </td>
  )
}

export default ElectronicSignatureTable