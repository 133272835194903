import React from 'react';
import { Button } from "react-bootstrap";
import ModalComponent from '../helpers_components/ModalComponent';

const TextAlertModal = ({
  headerText = '',
  textAlert = '',
  isTextAlertModalShowing,
  hadleCloseTextAlertModal = () => { },
  hadleAcceptTextAlertModal = () => { },
}) => {
  return (
    <>
      {
        isTextAlertModalShowing
        &&
        <ModalComponent
          header={headerText}
          body={<h4>{textAlert}</h4>}
          footer={
            <Button onClick={hadleAcceptTextAlertModal}>
              Aceptar
            </Button>
          }
          show={isTextAlertModalShowing}
          canClose={true}
          onHide={hadleCloseTextAlertModal}
          scroll={true}
        />
      }
    </>
  );
};
export default TextAlertModal;