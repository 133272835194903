import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Dropzone from "react-dropzone";
import Cookies from "js-cookie";
import MultiFile from "../../helpers_components/multifile/MultiFileField";
import DynamicFormElectronicSignatureType from './DynamicFormElectronicSignatureType';
import ElectronicSignatureProcessProvider from './ElectronicSignatureProcess/context/ElectronicSignatureProcessContext';
import DownloadPreviewDocumentModal from '../../governement_book/modals/DownloadPreviewDocumentModal';
import PreviewPdfModalProvider from '../../governement_book/context/previewPdfModalContext';

function DynamicFormFileType({
  el,
  validateCloudVisibility,
  onObservationClickHandler,
  disableFieldBasedMethod,
  onChangeHandler,
  props,
  state,
  setState,
  isImage,
  iconUrl,
  iconUrlByExt,
  onDrop,
  disableUploadButton,
  previewFileImgHandler,
  showErrorTextIfIsNotSigned,
  isEditingEnabledInFormElectronicSignature,
  setCurrentFormElectronicSignatureToEdit,
  enableNextFormElectronicSignature,
}) {

  const onDropFile = (files) => {
    onDrop(files, el);
  }

  if (el.extra_attributes.multifile === "si") {
    return (
      <MultiFile
        {...props}
        el={el}
        validateCloud={validateCloudVisibility}
        onObservationHandler={onObservationClickHandler}
        disableField={disableFieldBasedMethod}
        onChangeHandler={onChangeHandler}
      />
    );
  } else {
    const { userId_02 } = Cookies.get();
    let fileData = {
      route: state[el.name + "_img"]
        ? state[el.name + "_img"]
        : el.file_route,
      extension: state[el.name + "_ext"]
        ? state[el.name + "_ext"]
        : el.format,
      status: state[el.name + "_status"]
        ? state[el.name + "_status"]
        : el.file_route
          ? 2
          : 0,
      isImage: isImage(
        state[el.name + "_ext"] ? state[el.name + "_ext"] : el.format
      ),
      icon: state[el.name + "_ext"]
        ? iconUrl(state, el.name)
        : iconUrlByExt(el.format)
    };
    fileData['route'] = el['extra_attributes']['show_watermark'] ? fileData['route'] + '&user_id=' + userId_02 : fileData['route'];
    const shouldFileBeSigned = (el.extra_attributes && el.extra_attributes.sign && el.extra_attributes.sign !== 'no');
    const isDownloadModelEnabled = fileData.status === 2 && fileData.route && fileData.extension === 'pdf';

    return (
      <Col
        className={el.visibility["status"] ? "" : " d-none"}
        xs={12}
        md={el.width}
        style={{ marginBottom: '25px' }}
      >
        <Col xs={12}>
          <Form.Label
            className={
              "font-weight-bold " + (el.error ? "text-danger" : "")
            }
          >
            <span
              style={
                (shouldFileBeSigned || el.requiredSignal)
                  ? { display: "inline" }
                  : { display: "none" }
              }
            >
              <b>* </b>
            </span>
            {el.label}
            <span
              className="observation-input-icon"
              style={validateCloudVisibility(el)}
              onClick={() => onObservationClickHandler(el)}
            >
              <i
                className={
                  "far fa-comment-dots" +
                  (el.observations ? " text-danger" : "")
                }
              />
            </span>
          </Form.Label>
          <Form.Group
            style={disableFieldBasedMethod(el) ? { display: "none" } : null}
            controlId="formBasicFile"
          >
            <Row>
              <Col>
                {
                  shouldFileBeSigned
                    ?
                    <ElectronicSignatureProcessProvider>
                      <DynamicFormElectronicSignatureType
                        stateDynamicFormFileType={state}
                        setStateDynamicFormFileType={setState}
                        dynamicElementName={el.name}
                        onAddFiles={onDropFile}
                        showErrorTextIfIsNotSigned={showErrorTextIfIsNotSigned}
                        isEditingEnabled={isEditingEnabledInFormElectronicSignature}
                        setCurrentFormElectronicSignatureToEdit={setCurrentFormElectronicSignatureToEdit}
                        enableNextFormElectronicSignature={enableNextFormElectronicSignature}
                      />
                    </ElectronicSignatureProcessProvider>
                    :
                    <Dropzone onDrop={onDropFile}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="file-nilo-wrapper" {...getRootProps()}>
                          Seleccionar un archivo
                          <input
                            style={{ display: "inline!important" }}
                            className={
                              el.error ? " form-control is-invalid" : "form-control"
                            }
                            {...getInputProps()}
                            disabled={
                              disableFieldBasedMethod(el) || disableUploadButton
                            }
                            name={el.name}
                            key={el.id}
                          />
                        </div>
                      )}
                    </Dropzone>
                }
              </Col>
            </Row>
            <small
              id={`small${el.name}`}
              className="form-text text-muted mt-2"
            >
              {el.contextual_help ? el.contextual_help : ""}
            </small>
            <span
              style={
                el.error
                  ? { display: "inline", color: "red" }
                  : { display: "none" }
              }
            >
              {el.error ? "* " + el.error[0] : ""}
            </span>
          </Form.Group>

          <div
            className="file-upload-wrapper"
            style={
              fileData.isImage &&
                (fileData.status === 2 || fileData.status === 1)
                ? { display: "inline-block" }
                : { display: "none" }
            }
          >
            <div
              className={
                "file-upload-actions " +
                (fileData.status === 2 ? "d-block" : "d-none")
              }
            >
              <i
                className="fas fa-search-plus"
                style={{ cursor: "pointer" }}
                title="preview image"
                onClick={() => previewFileImgHandler(state, el)}
              />
              <i
                className="fas fa-cloud-download-alt"
                style={{ cursor: "pointer" }}
                title="dowload file"
                onClick={() => {
                  window.open(`${fileData.route}`, "_blank");
                }}
              />
            </div>
            <img
              className={
                "loader " + (fileData.status === 1 ? "d-block" : "d-none")
              }
              src={process.env.PUBLIC_URL + "/img/loader.gif"}
              alt="Cargando ..."
            />
            <img
              src={fileData.route}
              alt="File"
              className={fileData.status === 2 ? "d-block" : "d-none"}
              style={
                state[el.name]
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
            />
          </div>

          <div
            className={
              (
                !fileData.isImage && (fileData.status === 2)
                  ? "file-upload-wrapper d-block"
                  : !fileData.isImage && (fileData.status === 1)
                    ? "file-icon-wrapper d-block"
                    : "d-none"
              )
            }
          >
            <img
              className={
                "loader w-100 " +
                (fileData.status === 1 ? "d-block" : "d-none")
              }
              src={process.env.PUBLIC_URL + "/img/loader.gif"}
              alt="Cargando ..."
            />
            <a
              className={fileData.status === 2 ? "d-block" : "d-none"}
              href={fileData.route}
              target="_blank"
              rel="noopener noreferrer"
              title={fileData.route}
            >
              <img
                className={fileData.status === 2 ? "d-block" : "d-none"}
                src={fileData.icon}
                alt=""
              />
            </a>
          </div>
          {isDownloadModelEnabled && (
            <div className='mt-2'>
              <PreviewPdfModalProvider>
                <DownloadPreviewDocumentModal
                  document={{ file_path: fileData.route }}
                  documentType={el.label}
                  isDownloadDocumentButtonShowing={true}
                />
              </PreviewPdfModalProvider>
            </div>
          )}

        </Col>
      </Col>
    );
  }
}

export default DynamicFormFileType
