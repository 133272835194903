/* eslint-disable eqeqeq */
import axios from "axios";
import { animateScroll } from "react-scroll";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";

export class Request {
  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
    this.queryRetryCounter = 0;
    this.queryRetryLimit = 1;
  }

  async executeRequest(show = true, token = null) {
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };

    let authenticationToken = Cookies.get( "authentication_token_02" ) ?
                                String(Cookies.get( "authentication_token_02" )) :
                                btoa( process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION );
    
    if (token) {
      authenticationToken = token;
    }

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });
      const { clean, message } = response && response.data && response.data.data ? response.data.data : { clean: false };
      if ( clean ) {
        if (message) {
          ++this.queryRetryCounter;
          if (this.queryRetryCounter <= this.queryRetryLimit) {
            return this.executeRequest();
          }
          NotificationManager.error(message);
        }
        destroyCookies();
        // window.location.href = "/";
        return {
          code: 403,
          response: null,
          completeResponse: null
        };
      }

      if (response.data.code == "200")
        return {
          code: response.data.code,
          data: response
        };
      else if (
        typeof response.data === "object" &&
        response.data[0] &&
        response.data[0].hasOwnProperty("label")
      ) {
        return response.data;
      }

      //MOSTRAR COMPONENTE ALERT, CON EL DATA DEL RESPONSE DEL ERROR
      if (
        response.data.data.error &&
        typeof response.data.data.message == "string"
      ) {
        if (typeof response.data.data.code != "undefined") {
          responseError = {
            code: response.data.data.code,
            message: response.data.data.message
          };
        } else {
          // ESTAS LINEAS SON PARA EVITAR LA NOTIFICACION DE TERMINO NO ENCONTRADO
          // CUANDO SE DESEA VISUALIZAR EL AVISO DE PRIVACIDAD Y NO EXISTE NINGUNO
          if( response && 
              response.request && 
              response.request.responseURL && 
              response.request.responseURL.includes("terms/privacy")
            )
            return responseError = {
              code: response.data.code,
              message: response.data.data.message
            }
          // AQUI FINALIZA EL PARCHE PARA EVITAR LA NOTIFICACION DE TERMINO NO ENCONTRADO
          // CUANDO SE DESEA VISUALIZAR EL AVISO DE PRIVACIDAD Y NO EXISTE NINGUNO
          else 
            responseError = {
              code: response.data.code,
              message: response.data.data.message
            };
        }
      } else if (
        response.data.data.error &&
        typeof response.data.data.message == "object"
      ) {
        for (var [key, value] of Object.entries(response.data.data.message)) {
          const notificationContent = `${key}: ${value}`;
          if (Array.isArray(value) && value[0] === 'Archivo no válido.') {
            NotificationManager.warning(notificationContent);
          } else {
            NotificationManager.error(notificationContent);
          }
        }
        return (responseError = {
          code: response.data.code,
          message: response.data.data.message
        });
      } else if (typeof response.data.data.form == "object") {
        NotificationManager.error("Existen errores que impiden continuar, por favor revisa los campos remarcados en color rojo.");

        animateScroll.scrollToTop();

        return {
          code: response.data.code,
          form: response.data.data.form
        };
      } else if (typeof response.data.data.error == "object") {

        for (let errorItem in response.data.data.error) {
          setTimeout(() => {
            NotificationManager.error(
              response.data.data.error[errorItem],
              "Error en " + errorItem + ":"
            );
          }, 500);
        }

        return {
          code: response.status,
          message: response.data.data.error
        };
      } else if (
        typeof response.data.data.message == "object" &&
        typeof response.data.data.message.file == "object"
      ) {
        if (response.data.data.message.file.length > 0) {
          for (const messageError in response.data.data.message.file) {
            NotificationManager.error(
              response.data.data.message.file[messageError]
            );
          }
        }
        return response.data.data.message.file;
      } else if (response) {
        if (typeof response.data == "string" && response.data !== "") {
          responseError = {
            code: response.status,
            message: response.data
          };
        } else if (
          typeof response.data.data.code != "undefined" &&
          typeof response.data.data.data.message == "string" &&
          response.data.data.data.message !== ""
        ) {
          responseError = {
            code: response.data.data.code,
            message: response.data.data.data.message
          };
        }
      } else {
        //MOSTRAR COMPONENTE ALERT  CON EL DATA DEL RESPONSE DEL ERROR
        responseError = {
          code: response.data.code,
          message: response.data.data.message
        };
      }
    } catch (error) {
      //MOSTRAR COMPONENTE ALERT, CON EL DATA DEL RESPONSE DEL ERROR
      responseError = {
        code: 500,
        message: "El servidor no responde"
      };
    }
    NotificationManager.error(
      responseError.message,
      "Error " + responseError.code + ":"
    );

    return responseError;
  }
}
