import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ElectronicSignatureTable from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/componets/ElectronicSignatureTable';
import { useElectronicSignatureProcessContext } from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext';
import CredentialsModal from '../../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/credentialsModal/CredentialsModal';
import ToggleButton from '../../../helpers_components/toggleButton/ToggleButton';
import AddOrChangeMultiSignatureButton from '../../../buttons/addOrChangeMultiSignatureButton/AddOrChangeMultiSignatureButton';

const ElectronicSignatureProcessModalContent = ({
  disableSingleSignature = false,
  disableMultiSignature = false,
}) => {

  const {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal,
    onAddElectronicSignature,
    isMultipleSignature,
    setIsMultipleSignature,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    isThereASignature,
  } = useElectronicSignatureProcessContext();

  useEffect(() => {
    if (disableSingleSignature) {
      setIsMultipleSignature(true);
    } else if (disableMultiSignature) {
      setIsMultipleSignature(false);
    }
  }, [disableSingleSignature, disableMultiSignature]);

  const doesMultipleSignatureToogleEnabled = (!disableSingleSignature && !disableMultiSignature);

  return (
    <div>
      {
        doesMultipleSignatureToogleEnabled
        &&
        <ToggleButton
          onChange={(event) => setIsMultipleSignature(event.target.checked)}
          name='isMultipleSignature'
          size={'medium'}
        >
          Habilitar multifirma
        </ToggleButton>
      }

      <AddOrChangeMultiSignatureButton
        onClick={() => setIsElectronicSignatureModalSowing(true)}
        canSignatureBeChanged={(isThereASignature && !isMultipleSignature)}
      />

      {
        (Boolean(electronicSignaturesList.length && isMultipleSignature) || (!isMultipleSignature && Boolean(singleElectronicSignature)))
        &&
        <>
          <Form.Label className='d-block mt-3'>
            {isMultipleSignature ? 'Lista de Firmantes' : 'Firmante'}
          </Form.Label>
          <ElectronicSignatureTable
            electronicSignaturesList={electronicSignaturesList}
            singleElectronicSignature={singleElectronicSignature}
            isMultipleSignature={isMultipleSignature}
            onDelteElectronicSignatureById={onDelteElectronicSignatureById}
          />
        </>
      }
      {
        isElectronicSignatureModalSowing
        &&
        <CredentialsModal
          isModalSowing={isElectronicSignatureModalSowing}
          onCloseModal={onCloseModal}
          addElectronicSignatureHandle={onAddElectronicSignature}
          isMultipleSignature={isMultipleSignature}
        />
      }
    </div>
  )
}

export default ElectronicSignatureProcessModalContent;