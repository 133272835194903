import React, { useEffect, useState } from 'react';
import { Button, Form, Nav } from 'react-bootstrap';
import Dropzone from "react-dropzone";
import ModalComponent from '../../../../helpers_components/ModalComponent';
import { NotificationManager } from 'react-notifications';
import useElectronicSignatureManager from '../hooks/useElectronicSignatureManager';

const CredentialsModal = ({
  isModalSowing,
  onCloseModal,
  addElectronicSignatureHandle,
  isMultipleSignature,
}) => {
  const [electronicSignatureErrorText, setElectronicSignatureErrorText] = useState('');

  const {
    formElectronicSignatureValues,
    onSetElectronicSignatureKeyFile,
    onAddElectronicSignature,
    onFormElectronicSignatureChange,
    electroniSignatures,
  } = useElectronicSignatureManager({ isMultipleSignature });

  const handleResetElectronicSignatureErrorText = () => {
    if (Boolean(electronicSignatureErrorText)) {
      setElectronicSignatureErrorText('');
    }
  }

  useEffect(() => {
    handleResetElectronicSignatureErrorText();
  }, [formElectronicSignatureValues]);


  const onAddElectronicSignatureHandler = async () => {
    try {
      await onAddElectronicSignature(addElectronicSignatureHandle);
    } catch (error) {
      if (error.message === 'Invalid credentials') {
        NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
        setElectronicSignatureErrorText('Credenciales de firma electrónica inválidas');
      }
    }
  }

  const footerModal = (
    <>
      <Button variant="secondary" onClick={onCloseModal}>
        Cancelar
      </Button>
      <Button onClick={onAddElectronicSignatureHandler}>
        Agregar
      </Button>
    </>
  )

  return (
    <>
      {
        isModalSowing
        &&
        <ModalComponent
          header="Firma Electronica"
          body={<CredentialsBodyModal
            onFormElectronicSignatureChange={onFormElectronicSignatureChange}
            formElectronicSignatureValues={formElectronicSignatureValues}
            onSetElectronicSignatureKeyFile={onSetElectronicSignatureKeyFile}
            electronicSignatureErrorText={electronicSignatureErrorText}
            electroniSignatures={electroniSignatures}
            isMultipleSignature={isMultipleSignature}
          />}
          footer={footerModal}
          show={isModalSowing}
          canClose
          onHide={onCloseModal}
        />
      }
    </>
  )
}

export const CredentialsBodyModal = ({
  onFormElectronicSignatureChange,
  formElectronicSignatureValues,
  electronicSignatureErrorText,
  onSetElectronicSignatureKeyFile,
  electroniSignatures,
  isMultipleSignature,
}) => {
  const satFiles = (
    <>
      <SignatureInputFile
        label="* Llave privada (.cer)"
        setFile={(file) => onSetElectronicSignatureKeyFile(file, 'cer')}
        id={`cer`}
        accept=".cer"
        fileName={formElectronicSignatureValues.cer ? formElectronicSignatureValues.cer.name : ''}
      />

      <SignatureInputFile
        label="* Llave privada (.key)"
        setFile={(file) => onSetElectronicSignatureKeyFile(file, 'key')}
        id={`key`}
        accept=".key"
        fileName={formElectronicSignatureValues.key ? formElectronicSignatureValues.key.name : ''}
      />
    </>
  );

  const firelFiles = (
    <div>
      <SignatureInputFile
        label="* Llave privada (.pfx)"
        setFile={(file) => onSetElectronicSignatureKeyFile(file, 'pfx')}
        id={`pfx`}
        accept=".pfx, .p12"
        fileName={formElectronicSignatureValues.pfx ? formElectronicSignatureValues.pfx.name : ''}
      />
    </div>
  );

  return (
    <div className="border-bottom border-secondary mb-3 pb-3">
      <p className="m-0">
        * Firma Electrónica
      </p>
      <Nav className='mb-3' variant="tabs" defaultActiveKey={formElectronicSignatureValues.signaturetype}>
        {
          electroniSignatures.getTypes(isMultipleSignature).map((signature) => (
            <Nav.Item key={signature.id}>
              <Nav.Link onClick={onFormElectronicSignatureChange} eventKey={signature.type} name={'signaturetype'}>
                Firma {signature.displayName}
              </Nav.Link>
            </Nav.Item>
          ))
        }
      </Nav>

      <div className='mb-3'>
        {
          formElectronicSignatureValues.signaturetype === 'sat'
            ? satFiles
            : firelFiles
        }
      </div>
      <Form.Group className="mb-3" controlId="formGroupPasswordFiel">
        <Form.Label >
          * Contraseña
        </Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          onChange={onFormElectronicSignatureChange}
          value={formElectronicSignatureValues.password}
          name="password"
          autoComplete="off"
        />
        <Form.Text className="text-muted">
          Tu contraseña no se guardará en ningún momento, sólo se usará para obtener tu Fiel.
        </Form.Text>
      </Form.Group>
      {
        Boolean(electronicSignatureErrorText)
        &&
        <div className="text-danger mt-3">
          {electronicSignatureErrorText}
        </div>
      }
    </div>
  )
}

export const SignatureInputFile = props => {
  const { setFile, label, id, fileName, isMultiFiles = false, ...restOfProps } = props;
  const onDrop = files => {
    let file = isMultiFiles ? files : files[0];
    setFile(file);
  };

  return (
    <div className="margin-top-2em">
      <Form.Group controlId="formBasicFile">
        <Form.Label >
          {label}
        </Form.Label>
        <div className={'d-flex align-items-center'}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section >
                <div {...getRootProps()}>
                  <input
                    id={id}
                    name={id}
                    {...getInputProps()}
                    {...restOfProps}
                  />
                  <Button
                    variant="info"
                    size="sm"
                    className={'text-nowrap text-white'}
                  >
                    {fileName ? 'Modificar Archivo' : ' Agregar Archivo'}
                  </Button>
                </div>
              </section>
            )}
          </Dropzone>
          {
            fileName
            &&
            <div className='ml-2 p-3 bg-success text-white rounded text-break'>
              {fileName}
            </div>
          }
        </div>
      </Form.Group>
    </div>
  );
};

export default CredentialsModal