/* eslint-disable eqeqeq */
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { destroyCookies } from "../login/login_logic";

export class NewClassRequest {
  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
    this.queryRetryCounter = 0;
    this.queryRetryLimit = 1;
  }

  async executeRequest(show = true, token = null) {
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };
    let authenticationToken = Cookies.get( "authentication_token_02" ) && !this.usePublicToken(window.location.pathname) ?
      String(Cookies.get( "authentication_token_02" )) :
      btoa( process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION );

    if (token) {
      authenticationToken = token;
    }

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });
      const { clean, message } = response && response.data && response.data.data ? response.data.data : { clean: false };
      if ( clean ) {
        if (message) {
          ++this.queryRetryCounter;
          if (this.queryRetryCounter <= this.queryRetryLimit) {
            return this.executeRequest();
          }
          NotificationManager.error(message);
        }
        destroyCookies();
        // window.location.href = "/";
        return {
          code: 403,
          response: null,
          completeResponse: null
        };
      }

      if( response.status === 200 ){

        return {
          code: response.data.code,
          response: response.data.data,
          completeResponse: response
        }
      }

    } catch (error) {

        responseError = {
          code: 500,
          message: "El servidor no responde"
        };

        NotificationManager.error(
          responseError.message,
          "Error " + responseError.code + ":"
        );
    }

    return responseError;
  }

  usePublicToken(pathname) {
    const publicPaths = [
      '/boletin_judicial/consultar',
      '/expedientes/insertar',
    ];
    return publicPaths.includes(pathname);
  }
}
