export const getFileExtensionFromName = (fileName) => {
  if (typeof fileName !== 'string') {
    throw new Error('File name must be of type string');
  }

  const fineNameSplitted = fileName.split('.');
  if (fineNameSplitted.length < 2) {
    throw new Error('File name has no extension');
  }

  const filExtension = fineNameSplitted[fineNameSplitted.length - 1];
  return filExtension;
}