import React, { useState, useEffect, useContext } from "react";
import OneColumn from "../../layout/containers/OneColumn";
import { Container, Row, Button, Card, Col } from "react-bootstrap";
import ExpedientsSearcher from "../expedient_searcher/ExpedientsSearcher";
import { Grid } from "react-flexbox-grid";
import { RemotePagination } from "../../helpers_components/remote_pagination/RemotePagination";
import {
  columns, getDocumentsByExpedientNumber, getEvidencesDocument,
  bodyModal, FooterModal, bodyModalDocuments, footerModalDocuments,
  bodyModalNotifications, footerModalNotifications, AddPromotionBodyModal
} from "./expedient_details_logic";
import LoaderContext from "../../layout/shared/loader_context";
import ModalComponent from "../../helpers_components/ModalComponent";
import { NotificationManager } from "react-notifications";
import { fieldsValidation } from "../../search_for_lawyers/search_lawyers_logic";
import axios from "axios";
import Cookies from "js-cookie";
import TextAlertModal from "../../helpers_components/TextAlertModal";
import { getFileExtensionFromName } from "../../../services/dataFormatting/getFileExtensionFromName";
import ElectronicSignatureProcessProvider from "../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext";
import { getFileNameForElectronicSignature } from "../../../services/dataFormatting/electronicSignature";
import _ from "lodash";
import ChangeCourtTable from "./components/changeCourtTable/ChangeCourtTable";
import Cover from "../../layout/shared/Cover";
let pageConfig = {
  active: '',
  title: ''
};

const ExpedientDetails = (props) => {
  const loader = useContext(LoaderContext);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [textNotDataDocuments, setTextNotDataDocuments] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [dataExpedient, setDataExpedient] = useState({});
  const expedientNumber = props.match.params.expedient;
  const [documentsState, setDocumentsData] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [promotion, setPromotion] = useState({});
  const { authentication_token_02, names_02, paternal_surname_02 } = Cookies.get();
  const [imageURL, setImageURL] = useState("");
  const [typePromotion, setTypePromotion] = useState({});
  const [dataUploadEvidences, setDataUploadEvidences] = useState(null);
  const [showModalNotifications, setShowModalNotifications] = useState(false);
  const [commentModal, setCommentModal] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isActorAMoralRegime, setIsActorAMoralRegime] = useState(false);
  const [isDefendantAMoralRegime, setIsDefendantAMoralRegime] = useState(false);
  const [isPromotionSuccessfullyReceivedModalShowing, setIsPromotionSuccessfullyReceivedModalShowing] = useState(false);
  const [areDocumentsExpedientSearching, setAreDocumentsExpedientSearching] = useState(false);
  const [areDocumentsExpedientCleaning, setAreDocumentsExpedientCleaning] = useState(false);
  const [isAddSignatureModalShowing, setIsAddSignatureModalShowing] = useState(false);

  useEffect(() => {
    onGetDocumentsByExpedientNumber();
  }, []);

  useEffect(() => {
    if (!dataExpedient)
      return
    if (dataExpedient.actor_regime === 'moral')
      setIsActorAMoralRegime(true)
    if (dataExpedient.defendant_regime === 'moral')
      setIsDefendantAMoralRegime(true)
  }, [dataExpedient])

  const onGetDocumentsByExpedientNumber = async (textToSearch = '') => {
    try {
      loader.show(true);
      await getDocumentsByExpedientNumber(
        expedientNumber,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        textToSearch ? textToSearch : '',
      );
    } catch (error) {
      console.error(error);
    } finally {
      loader.show(false);
    }
  }

  const setStateDocuments = async (documentId, isNotification) => {
    loader.show(true);
    if (isNotification) {
      await getEvidencesDocument(
        documentId,
        setDocumentsData,
        setShowModalNotifications
      );
    } else {
      await getEvidencesDocument(
        documentId,
        setDocumentsData,
        setShowDocuments
      );
    }
    loader.show(false);
  };

  const handleShowNewDocument = () => {
    setShow(true);
  };

  const searchDocuments = async () => {
    if (!search) {
      return
    }
    setPage(1);
    setAreDocumentsExpedientSearching(true)
    await onGetDocumentsByExpedientNumber(search);
    setAreDocumentsExpedientSearching(false)
  };

  const cleanSearchDocuments = async () => {
    if (!search) {
      return
    }
    setAreDocumentsExpedientCleaning(true);
    await onGetDocumentsByExpedientNumber();
    setSearch('');
    setAreDocumentsExpedientCleaning(false);
  };

  const closeModalDocuments = () => {
    setDocumentsData([]);
    setShowDocuments(false);
  };

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    await onGetDocumentsByExpedientNumber();
  };

  const setCloseModal = () => {
    setDataUploadEvidences(null);
    setErrors({});
    setPromotion({});
    setTypePromotion({});
    setImageURL("");
    setShow(false);
  };

  const onDrop = (files) => {

    if (files.length > 0) {

      let filExtension = '';
      try{
        filExtension = getFileExtensionFromName(files[0].name);
      } catch (error) {
        NotificationManager.error(error.message);
      }

        if (!['pdf', 'doc', 'docx'].includes(filExtension)) {
          NotificationManager.error("El documento debe ser de tipo pdf, doc o docx");
          return
        }

      if (files[0]["type"].includes("image")) {
        setImageURL(URL.createObjectURL(files[0]));
        setTypePromotion({ type: "image", name: files[0]["name"] });
      }
      else setTypePromotion({ type: "document", name: files[0]["name"] });

      const reader = new FileReader();

      reader.onload = (event) => {
        setErrors({ ...errors, file: [] });
        setPromotion({ ...promotion, file: files[0] });
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const reloadComponent = async (message, title, time) => {
    await getDocumentsByExpedientNumber(
      expedientNumber.replace(/\//g, '-'),
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments,
      setCommentModal,
      setShowCommentModal
    );

    setCloseModal();
    NotificationManager.success(
      message ? message : "El archivo fue cargado correctamente",
      title ? title : "",
      time ? time : 3000
    );
    setIsPromotionSuccessfullyReceivedModalShowing(true);
  };

  async function handleSubmitPromotion(pkcs7) {
    const requiredFields = promotion["hasProofs"] !== "si" ?
      ["alias", "file", "promoter", "hasProofs"] :
      ["alias", "file", "promoter", "hasProofs", "evidence_file"];
    const responseValidation = fieldsValidation(requiredFields, promotion);
    if (typeof responseValidation === "object") {
      NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
      setErrors(responseValidation);
    }
    else {
      loader.show(true);
      let formData = new FormData();
      formData.append('expedient_id', expedientNumber);

      let keysDataToSend = [
        "alias",
        "file",
        "promoter",
        "hasProofs",
        "expedient_number",
        "comment",
        'Pkcs7Original',
        'CertificateType',
        'FileName',
        'UserName',
        'document_type',
      ];
      if (promotion.hasProofs === "si") {
        keysDataToSend.push("evidence_file");
      }

      const promotionToSend = {
        ...promotion,
        Pkcs7Original: pkcs7,
        CertificateType: 3,
        FileName: getFileNameForElectronicSignature(typePromotion),
        UserName: `${names_02} ${paternal_surname_02}`,
        document_type: 'promotion',
      } 

      for (let dataKey of keysDataToSend) {
        if (dataKey === 'expedient_number') formData.append('expedient_number', expedientNumber.replace(/\//g, '-'))
        else formData.append(dataKey, promotionToSend[dataKey] ? promotionToSend[dataKey] : "");
      };

      if (dataUploadEvidences == null) {
        const responseDocumentSave = await axios({
          method: "post",
          url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/upload`,
          data: formData,
          headers: {
            Authorization: String(authentication_token_02)
          }
        });

        if (
          promotionToSend['hasProofs'] === 'si' &&
          responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
          responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
          typeof responseDocumentSave.data.data.documentExpedient === 'object' &&
          responseDocumentSave.data.data.promotion_id
        ) {
          setDataUploadEvidences({
            promotion_id: responseDocumentSave.data.data.promotion_id,
            documentUpload: responseDocumentSave.data.data.documentExpedient,
            change: new Date().getTime()
          });
        }
        else if (
          promotionToSend['hasProofs'] === 'no' &&
          responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
          responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
          typeof responseDocumentSave.data.data.documentExpedient === 'object'
        ) {
          await reloadComponent();
        }
        else if (
          responseDocumentSave.data &&
          responseDocumentSave.data.data &&
          responseDocumentSave.data.data.error
        ) {
          const errorMessages = responseDocumentSave.data.data.message;
          if (typeof errorMessages === 'object') {
            for (const errorMessageItem in errorMessages) {
              NotificationManager.error(errorMessages[errorMessageItem]);
            }
          } else {
            NotificationManager.error(errorMessages)
          }
        }
      }
      loader.show(false);
    }
  };

  return (
    <OneColumn {...props} pageConfig={pageConfig} hideTopMenu={true} fluid={true} noTitle={true}>
      <Container fluid={true} className="bg-size-cover" style={{ minHeight: '76vh' }}>
        <div className="container-header-expedient-documents-details mT-10">
          <div className="text-center">
            <h3>
              {dataExpedient['expedient_number']}
            </h3>
          </div>
          <div className=".box-header-expedient-documents-details">
            <Button
              onClick={() => props.history.goBack()}
            >
              <i className="fas fa-arrow-left" /> Volver
            </Button>
          </div>
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Card
            className="cardExpedientDetails"
            id="coverPDF"
          >
            <Card.Body>
              <Container>
                <div>
                  <Cover expedientId={expedientNumber}/>
                </div>

                {
                  (Boolean(_.get(dataExpedient, 'history.length')))
                  &&
                  <>
                    <div>
                      <h5 style={{ fontWeight: 'bold' }} >
                        Transferencia de juzgados
                      </h5>
                      <div className="table-responsive text-center" >
                        <ChangeCourtTable expedientId={dataExpedient.id} changeCourtTableData={_.get(dataExpedient, 'history', [])} />
                      </div>
                    </div>
                    <br />
                  </>
                }

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <b>Iniciado en: {dataExpedient['date'] || ''}</b>
                </div>
              </Container>
            </Card.Body>
          </Card>
        </Row>

        <div className="optionsExpedientDocuments">
          <h4 className="w-100 text-center mB-30">Documentos del Expediente</h4>

          <div className="newDocument">
            <Button
              onClick={() => handleShowNewDocument()}
            >
              <i className="fas fa-plus" />
              {'  '} Agregar promoción
            </Button>
          </div>

          <div className="clearFloat" />
          <div className="containerSearcherDocuments">
            <ExpedientsSearcher
              placeholderSearch="Escribe el nombre del documento"
              placeholderSearchButton="Buscar documento"
              placeholderCleanButton="Limpiar"
              helperContextual="Escribe el nombre del documento y haz clic en el botón Buscar documento."
              executeSearch={searchDocuments}
              cleanSearch={cleanSearchDocuments}
              isSearching={areDocumentsExpedientSearching}
              isCleaning={areDocumentsExpedientCleaning}
              setSearch={setSearch}
              search={search}
              hasRulesToSearch={false}
            />
          </div>
        </div>

        <Grid
          fluid={true}
          className={dataDocuments.length > 0 ? "no-padding" : "d-n"}
          style={{ minHeight: '76vh', width: "65%", margin: "0px auto" }}
        >
          <section className="procedures-table-container mB-50">
            <RemotePagination
              data={dataDocuments}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalDocuments}
              onTableChange={handleTableChange}
              columns={columns}
            />

          </section>
        </Grid>

        <h4 className={dataDocuments.length === 0 && textNotDataDocuments !== "" ? "text-center clearFloat" : "d-n"}>{textNotDataDocuments}</h4>
      </Container>

      {
        show
        &&
        <ElectronicSignatureProcessProvider>
          <ModalComponent
            header="Agregar promoción"
            body={
              <AddPromotionBodyModal
                setErrors={setErrors}
                setPromotion={setPromotion}
                errors={errors}
                promotion={promotion}
                imageURL={imageURL} onDrop={onDrop}
                typePromotion={typePromotion}
                expedientNumber={expedientNumber}
                reloadComponent={reloadComponent}
                dataUploadEvidences={dataUploadEvidences}
                setIsAddSignatureModalShowing={setIsAddSignatureModalShowing}
              />
            }
            footer={<FooterModal setCloseModal={setCloseModal} handleSubmitPromotion={handleSubmitPromotion} />}
            show={show}
            canClose={true}
            onHide={setCloseModal}
            isChildModalRendering={isAddSignatureModalShowing}
          />
        </ElectronicSignatureProcessProvider>
      }

      <ModalComponent
        header="Documentos de pruebas"
        body={bodyModalDocuments(showDocuments ? documentsState : [])}
        footer={footerModalDocuments(closeModalDocuments)}
        show={showDocuments}
        canClose={true}
        onHide={setShowDocuments}
        scroll={true}
      />

      <TextAlertModal
        headerText={'Promoción ha sido recibida exitosamente'}
        textAlert={<>Tu promoción ha sido recibida exitosamente por el juzgado, podrás visualizarla en el listado de tus documentos cuando sea respondida por el juzgado mediante un acuerdo. <b> Verifica tu correo electrónico para visualizar tu acuse de recibido.</b> </>}
        isTextAlertModalShowing={isPromotionSuccessfullyReceivedModalShowing}
        hadleCloseTextAlertModal={() => setIsPromotionSuccessfullyReceivedModalShowing(false)}
        hadleAcceptTextAlertModal={() => setIsPromotionSuccessfullyReceivedModalShowing(false)}
      />

      <ModalComponent
        header="Notificaciones:"
        body={bodyModalNotifications(showModalNotifications ? documentsState : [])}
        footer={footerModalNotifications(setShowModalNotifications)}
        show={showModalNotifications}
        canClose={true}
        onHide={setShowModalNotifications}
        scroll={true}
        dialogClassName="modal-templates-preview"
      />

      <ModalComponent
        header="Comentario"
        body={
          <Row>
            <Col>
              <div style={{ overflowY: 'auto', maxHeight: '65vh' }}>
                <div className="scroll">
                  {commentModal}
                </div>
              </div>
            </Col>
          </Row>
        }
        footer={<Button onClick={() => setShowCommentModal(false)}>Cerrar</Button>}
        show={showCommentModal}
        canClose={true}
        onHide={setShowCommentModal}
      />


    </OneColumn>
  );
};

export default ExpedientDetails;
