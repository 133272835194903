import React, { useState, useEffect } from 'react';
import OneColumn from '../../layout/containers/OneColumn';
import { Col, Row } from 'react-flexbox-grid';
import { getNotificationById, bodyDocumentsView, footerDocumentsView } from './notification_received_logic';
import ModalComponent from '../../helpers_components/ModalComponent';

const pageConfig = {
  active: 'home',
  title: 'Home'
};

const NotificationReceived = (props) => {
  const { notification_id } = props.match.params;
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(0);
  const [pauseCarousel, setPauseCarousel] = useState(false);

  useEffect(() => {
    (async function() {
      getNotificationById(
        notification_id,
        setData,
        setShow
      );
    })();
  }, []);


  return (
    <OneColumn {...props} pageConfig={pageConfig}>
      <section className='home'>
        <Row center='xs'>
          <Col xs={12}>
            <ModalComponent
              header='Acuse de recibido'
              body={bodyDocumentsView(
                currentDocument, setCurrentDocument, 
                data, pauseCarousel, setPauseCarousel
              )}
              footer={footerDocumentsView(setShow)}
              show={show}
              canClose={true}
              onHide={setShow}
              dialogClassName='modal-templates-preview'
            />
          </Col>
        </Row>
      </section>
    </OneColumn>
  );
};

export default NotificationReceived;