import _ from "lodash";
import { NewClassRequest } from "../../../../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";

export const getExpedientDetails = async (accessToken) => {
  const expedientDetailsRequestUrl = `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/${accessToken}`;
  const expedientDetailsRequestArguments = [
    expedientDetailsRequestUrl,
    "get",
    null,
    {},
  ];

  const expedientDetailsRequest = new NewClassRequest(...expedientDetailsRequestArguments);
  const expedientDetailsResponse = await expedientDetailsRequest.executeRequest();
  const expedientDetailsResponseCode = _.get(expedientDetailsResponse, 'code');
  const isASuccessfulResponse = expedientDetailsResponseCode === 200;

  if (!isASuccessfulResponse) {
    const expedientDetailsResponseMessage = _.get(expedientDetailsResponse, 'response.message');
    if (expedientDetailsResponseMessage) {
      NotificationManager.error(expedientDetailsResponseMessage);
    }
    return {};
  }

  return expedientDetailsResponse.response;

}